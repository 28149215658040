import React, { Component } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { API_GET_PLAYER_LIST_URL } from '../../config';
import axiosInstance from '../../axiosInstance';

/**
 * Attivazione modale per switchare un pagamento
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalChangePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceId: this.props.deviceId,
            newDeviceId: '',
            paymentId: this.props.paymentId,
            providerTransactionId: this.props.providerTransactionId,

            newDeviceIdNull: false,

            // parametri per autocomplete
            listPlayers: [],
            deviceIdFromList: '',
            activeSeeAutocomplete: false
        }
    }

    confirm = () => {
        if (this.state.newDeviceId !== '') {
            let data = {
                newDeviceId: this.state.newDeviceId,
                oldDeviceId: this.state.deviceId,
                providerTransactionId: this.state.providerTransactionId
                // paymentId: this.state.paymentId
            }
            this.props.updateDevicePayment(data);
        } else {
            this.props.errorNotify('Inserisci il nuovo Device ID!');
            this.setState({ newDeviceIdNull: true });
        }
    }

    /**
     * Per attivare l'autocompletamento del device,
     * Carica tutti i device appena apri la modale
     */
    componentDidMount() {
        this.getListDevice();
    }

    /**
     * Chiama il BE a ogni cambiamento di carattere dell'id device
     */
    setChangeId = (event) => {
        this.setState({ deviceIdFromList: event.target.value });

        if (event.target.value === '') {
            this.setState({ activeSeeAutocomplete: false });
        } else if (!this.state.activeSeeAutocomplete) {
            this.setState({ activeSeeAutocomplete: true });
        }

        setTimeout(() => {
            this.getListDevice();
        }, 100);
    }

    /**
     * Setta il valore, al click dell'item autocomplete
     */
    setValueChangeId = (value) => {
        this.setState({
            newDeviceId: value,
            deviceIdFromList: value,
            activeSeeAutocomplete: false
        });
    }

    /**
     * Carica "solamente" 10 device per iniziare
     */
    getListDevice = async () => {
        try {
            let params = {
                'elementsByPage': '20',
                'filters[0][DeviceId]': this.state.deviceIdFromList,
            };

            let response = await axiosInstance.get(`${API_GET_PLAYER_LIST_URL}`, { params: params });

            let arrayTemp = [];

            Object.values(response.data.value.value).forEach((k) => {
                arrayTemp.push(k._id);
            })

            this.setState({ listPlayers: arrayTemp });


        } catch (result) {
            const { errorNotify } = this.props;
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(`Error ${result.response.status} su ${API_GET_PLAYER_LIST_URL} - GET`); break;
                }
            }
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalPlayer">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label text-end">ID Pagamento</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" value={this.state.paymentId} readOnly />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label text-end">Provider Transaction ID</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" value={this.state.providerTransactionId} readOnly />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label text-end">ID Vecchio Device</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" value={this.state.deviceId} readOnly />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label text-end">ID Nuovo Device</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" value={this.state.deviceIdFromList} onChange={this.setChangeId} />
                        {
                            this.state.activeSeeAutocomplete &&
                            <ul className="autoCompleteDevice">
                                {
                                    this.state.listPlayers.map((item, key) => {
                                        return (
                                            <li key={key} onClick={() => this.setValueChangeId(item)} >
                                                {item}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        }
                    </div>
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}