import React, { Component, Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Radio } from '@mui/material';
import { API_PAYMENT } from '../../config';

import './style.scss';
import { countries } from '../countries';
import Loading from '../loading';
import axiosInstance from '../../axiosInstance';

/**
 * Attivazione modale per aggiungere un nuovo pagamento
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "userMail" : mail dell'utente a cui mandare la mail
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalCreatePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            // dati del prodotto
            productName: '',
            qty: 0,
            price: '',

            // dati per la creazione del pagamento
            email: '',
            name: '',
            surname: '',
            code: '',
            firstAddress: '',
            secondAddress: '',
            telephone: '',
            cap: '',
            city: '',
            province: '',
            country: '',

            // array contentente i prodotti da fatturare
            arrayProduct: [{
                productName: '',
                qty: 0,
                price: 0.0,
                discount: 0
            }],

            verify: false,

            arrayProva: [{
                productName: '',
                qty: 0,
                price: 0.0,
            }],

            // variabile che indica se prendere(false) il prezzo di listino o quello a scelta dell'utente(true)
            checkedForce: false,

            selectRadio: ''
        }
    }

    setChange = (event) => {
        if (event.target.name === 'checkedForce') {
            this.setState({ checkedForce: !this.state.checkedForce });
        } else if (event.target.name === 'qty') {
            this.setState({ checkedForce: parseInt(event.target.value) });
        } else if (event.target.name === 'price') {
            this.setState({ checkedForce: parseFloat(event.target.value) });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    /**
     * Aggiunge un prodotto nuovo all'array dei prodotti.
     */
    addProduct = () => {
        let newProduct = {
            productName: '',
            qty: 0,
            price: 0.0,
            discount: 0
        };
        const arrayProduct = [...this.state.arrayProduct, newProduct];
        this.setState({ arrayProduct });
    }

    /**
     * Aggiorna l'oggetto selezionato che si trova all'interno dell'array dei prodotti.
     * 
     * @param {*} k è l'indice in cui si trova l'oggetto
     * @param {*} i è l'oggetto modificato
     */
    updateItem = (k, i) => {
        let arrayProduct = [...this.state.arrayProduct];
        let item = { ...arrayProduct[k] };

        if (!this.state.checkedForce) {
            item = {
                productName: i.productName,
                qty: i.qty,
                price: this.returnPriceForProduct(i),
                discount: i.discount
            }
        } else {
            item = i;
        }

        arrayProduct[k] = item;
        this.setState({ arrayProduct });
    }

    /**
     * Rimuove un prodotto dall'array dei prodotti.
     */
    removeProduct = (k) => {
        this.setState(state => {
            // eslint-disable-next-line
            const arrayProduct = state.arrayProduct.filter((item, i) => k !== i);
            return { arrayProduct }
        });
    }

    verifyProduct = () => {
        // eslint-disable-next-line
        this.state.arrayProduct.map((el) => {
            if (el.productName === this.state.arrayProva[0].productName) {
                this.setState({ verify: true });
            } else if (el.qty === this.state.arrayProva[0].qty) {
                this.setState({ verify: true });
            } else {
                this.setState({ verify: false });
            }
        })

        setTimeout(() => {
            this.confirm();
        }, 100);
    }

    confirm = () => {
        const { errorNotify } = this.props;

        if (this.state.email === '') {
            errorNotify('Inserisci una mail');
        } else if (this.state.selectRadio === 'privato' && this.state.name === '') {
            errorNotify('Inserisci un nome');
        } else if (this.state.selectRadio === 'azienda' && this.state.surname === '') {
            errorNotify('Inserisci una Ragione Sociale');
        } else if (this.state.selectRadio === 'privato' && this.state.surname === '') {
            errorNotify('Inserisci un cognome');
        } else if (this.state.country === '') {
            errorNotify('Inserisci un country');
        } else if (this.state.verify) {
            errorNotify('Inserisci i prodotti correttamente');
        } else if (this.state.selectRadio === 'azienda' && this.state.code === '') {
            errorNotify('Inserisci una partita iva');
        } else {
            this.setState({ isLoading: true });
            let that = this;

            let data = {
                "force": this.state.checkedForce,
                "products": this.sanitizeArray(this.state.arrayProduct),
                "author": {
                    "email": this.state.email,
                    "address": {
                        "name": this.state.name,
                        "isBusiness": this.state.selectRadio === 'azienda',
                        "surname": this.state.surname,
                        "code": this.state.code,
                        "firstAddress": this.state.firstAddress,
                        "telephone": this.state.telephone,
                        "cap": this.state.cap,
                        "city": this.state.city,
                        "province": this.state.province,
                        "country": this.state.country,
                    }
                }
            }

            axiosInstance.post(API_PAYMENT, data)
                .then(() => {
                    that.props.toggle();
                    that.props.successNotify('Nuovo Pagamento Creato! 😉');
                    that.setState({ isLoading: false });
                })
                .catch(result => {
                    that.setState({ isLoading: false });
                    if (result && result.response && result.response.status) {
                        that.props.errorNotify(`Error ${result.response.status} su ${API_PAYMENT} - POST`);
                    }
                });
        }
    }

    /**
     * Prima di essere mandati i dati, modifico i dati come li vuole il BE.
     * La quantità dovrà essere di tipo int.
     * Il price e il discount dovranno essere di tipo float (massimo due posizioni dopo la virgola).
     */
    sanitizeArray = (data) => {
        let dataTemp = [];

        // eslint-disable-next-line
        data.map(el => {
            dataTemp.push({
                productName: el.productName,
                qty: parseInt(el.qty),
                price: parseFloat(el.price.toFixed(2)),
                discount: parseFloat((el.discount / 100).toFixed(2))
            })
        })

        return dataTemp;
    }

    /**
     * Ritorna il prezzo associato al nome del prodotto che abbiamo in listino.
     * Serve solo a mostrarlo all'utente, poi il BE metterà i prezzi in automatico, 
     * prendendoli dal listino.
     * 
     * @param {*} item 
     */
    returnPriceForProduct = (item) => {
        const { preferences } = this.props;
        let price;
        // eslint-disable-next-line
        preferences.allProducts.map(el => {
            if (el.name === item.productName) {
                price = el.price;
            }
        })
        return price;
    }

    render() {
        const { stateModal, toggle, titleModal, preferences } = this.props;

        return (
            <Fragment>
                {this.state.isLoading && <Loading />}

                <Dialog open={stateModal} disableScrollLock={true} className="modalPayment">
                    <DialogTitle>
                        {titleModal}
                        <IconButton
                            aria-label="close"
                            onClick={toggle}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <i className='fas fa-times'></i>
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <div className="row">
                            <div className="col-12">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.setChange}
                                            checked={this.state.checkedForce}
                                            name="checkedForce"
                                        />
                                    }
                                    label="Prodotti e prezzi personalizzati"
                                />
                            </div>
                        </div>
                        {
                            this.state.arrayProduct.map((el, key) => {
                                return (
                                    <div key={key} className="row rowProduct" style={{ 'marginBottom': '15px' }}>
                                        <div className="col-4">
                                            {
                                                !this.state.checkedForce ?
                                                    <FormControl className='customSelect'>
                                                        <InputLabel>Prodotto</InputLabel>
                                                        <Select
                                                            labelId="keyProductName"
                                                            name="productName"
                                                            variant='standard'
                                                            key={key}
                                                            value={this.state.arrayProduct[key].productName}
                                                            onChange={(event) => {
                                                                this.updateItem(key, {
                                                                    productName: event.target.value,
                                                                    qty: this.state.arrayProduct[key].qty,
                                                                    price: this.state.arrayProduct[key].price,
                                                                    discount: this.state.arrayProduct[key].discount
                                                                })
                                                            }}
                                                        >
                                                            {
                                                                preferences.allProducts.map((item, k) => {
                                                                    return (
                                                                        <MenuItem key={k} value={item.name}>{item.description}</MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    :
                                                    <TextField
                                                        name="productName"
                                                        label="Prodotto"
                                                        variant='standard'
                                                        value={this.state.arrayProduct[key].productName}
                                                        onChange={(event) => {
                                                            this.updateItem(key, {
                                                                productName: event.target.value,
                                                                qty: this.state.arrayProduct[key].qty,
                                                                price: this.state.arrayProduct[key].price,
                                                                discount: this.state.arrayProduct[key].discount
                                                            })
                                                        }}
                                                    />
                                            }
                                        </div>
                                        <div className="col-1">
                                            <TextField
                                                name="qty"
                                                label="Quantità"
                                                variant='standard'
                                                value={this.state.arrayProduct[key].qty}
                                                onChange={(event) => {
                                                    this.updateItem(key, {
                                                        productName: this.state.arrayProduct[key].productName,
                                                        qty: parseInt(event.target.value),
                                                        price: this.state.arrayProduct[key].price,
                                                        discount: this.state.arrayProduct[key].discount
                                                    })
                                                }}
                                                type="number"
                                            />
                                        </div>
                                        <div className="col-2">
                                            {
                                                !this.state.checkedForce ?
                                                    <TextField
                                                        name="price"
                                                        label="Prezzo Singolo"
                                                        variant='standard'
                                                        value={this.state.arrayProduct[key].price}
                                                        disabled
                                                    />
                                                    :
                                                    <TextField
                                                        name="price"
                                                        label="Prezzo Singolo"
                                                        variant='standard'
                                                        type="number"
                                                        value={this.state.arrayProduct[key].price}
                                                        onChange={(event) => {
                                                            this.updateItem(key, {
                                                                productName: this.state.arrayProduct[key].productName,
                                                                qty: this.state.arrayProduct[key].qty,
                                                                price: parseFloat(event.target.value),
                                                                discount: this.state.arrayProduct[key].discount
                                                            })
                                                        }}
                                                    />
                                            }
                                        </div>

                                        <div className="col-2">
                                            <TextField
                                                name="discount"
                                                label="Sconto (%)"
                                                value={this.state.arrayProduct[key].discount}
                                                variant='standard'
                                                onChange={(event) => {
                                                    this.updateItem(key, {
                                                        productName: this.state.arrayProduct[key].productName,
                                                        qty: this.state.arrayProduct[key].qty,
                                                        price: this.state.arrayProduct[key].price,
                                                        discount: parseInt(event.target.value)
                                                    })
                                                }}
                                                type="number"
                                            />
                                        </div>

                                        <div className="col-2">
                                            <TextField
                                                name="priceTotal"
                                                label="Prezzo Totale"
                                                value={parseFloat(((this.state.arrayProduct[key].price - ((this.state.arrayProduct[key].price * this.state.arrayProduct[key].discount) / 100)) * this.state.arrayProduct[key].qty).toFixed(2))}
                                                variant='standard'
                                            />
                                        </div>

                                        <div className="col">
                                            <div className="iconCancel" onClick={() => { this.removeProduct(key) }}>
                                                <i className="fas fa-times"></i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="row">
                            <div className="col-1" style={{ 'padding': '0' }}>
                                <div className="iconPlus" onClick={this.addProduct}>
                                    <i className="fas fa-plus"></i>
                                </div>
                            </div>

                            <div className="col" style={{ 'paddingLeft': '0' }}>
                                <hr className="hrForPlus" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <FormControlLabel value="male" control={
                                    <Radio
                                        checked={this.state.selectRadio === 'azienda'}
                                        onChange={this.setChange}
                                        value="azienda"
                                        name="selectRadio"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                } label="Azienda" />

                                <FormControlLabel value="male" control={
                                    <Radio
                                        checked={this.state.selectRadio === 'privato'}
                                        onChange={this.setChange}
                                        value="privato"
                                        name="selectRadio"
                                        inputProps={{ 'aria-label': 'B' }}
                                    />
                                } label="Privato" />
                            </div>
                        </div>

                        {
                            this.state.selectRadio !== '' &&
                            <Fragment>
                                <div className="row" style={{ 'marginBottom': '15px' }}>
                                    <div className="col-4">
                                        <TextField
                                            name="surname"
                                            variant='standard'
                                            label={this.state.selectRadio === 'azienda' ? "Ragione Sociale" : "Cognome"}
                                            value={this.state.surname}
                                            onChange={this.setChange}
                                        />
                                    </div>

                                    <div className="col-4">
                                        <TextField
                                            name="name"
                                            variant='standard'
                                            label={this.state.selectRadio === 'azienda' ? "Nome Cognome" : "Nome"}
                                            value={this.state.name}
                                            onChange={this.setChange}
                                        />
                                    </div>

                                    <div className="col-4">
                                        <TextField
                                            name="code"
                                            variant='standard'
                                            label={this.state.selectRadio === 'azienda' ? "Partita IVA" : "Codice Fiscale"}
                                            value={this.state.code}
                                            onChange={this.setChange}
                                        />
                                    </div>
                                </div>

                                <div className="row" style={{ 'marginBottom': '15px' }}>
                                    <div className="col-4">
                                        <TextField
                                            name="email"
                                            label="Email"
                                            variant='standard'
                                            value={this.state.email}
                                            onChange={this.setChange}
                                        />
                                    </div>

                                    <div className="col-4">
                                        <TextField
                                            name="firstAddress"
                                            label="Indirizzo 1"
                                            variant='standard'
                                            value={this.state.firstAddress}
                                            onChange={this.setChange}
                                        />
                                    </div>

                                    <div className="col-4">
                                        <TextField
                                            name="telephone"
                                            label="Telefono"
                                            variant='standard'
                                            value={this.state.telephone}
                                            onChange={this.setChange}
                                        />
                                    </div>
                                </div>

                                <div className="row" style={{ 'marginBottom': '15px' }}>
                                    <div className="col-2">
                                        <TextField
                                            name="cap"
                                            label="CAP"
                                            variant='standard'
                                            value={this.state.cap}
                                            onChange={this.setChange}
                                        />
                                    </div>

                                    <div className="col-3">
                                        <TextField
                                            name="province"
                                            label="Provincia"
                                            variant='standard'
                                            value={this.state.province}
                                            onChange={this.setChange}
                                        />
                                    </div>

                                    <div className="col-3">
                                        <FormControl className='customSelect'>
                                            <InputLabel>Country</InputLabel>
                                            <Select
                                                labelId="keyCountry"
                                                name="country"
                                                variant='standard'
                                                value={this.state.country}
                                                onChange={this.setChange}
                                            >
                                                {
                                                    countries.map((item, k) => {
                                                        return (
                                                            <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-4">
                                        <TextField
                                            name="city"
                                            label="Città"
                                            variant='standard'
                                            value={this.state.city}
                                            onChange={this.setChange}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </DialogContent>

                    <DialogActions>
                        <button className="btn btn-success" onClick={this.verifyProduct}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}