import React, { Component, Fragment } from 'react';
import { API_PREFERENCES, SUPER_ADMIN, VPN_SETTING_PROXY } from '../../config';

import './style.scss';
import Loading from '../../common/loading';
import ModalVpnSetting from '../../common/modal/modalVpnSetting';
import { returnNationality } from '../../common/actions/returnNationality';
import { isEmpty, withNavigate } from '../../utility';
import axiosInstance from '../../axiosInstance';

class vpnSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loadingForRole: true,
            hasClickNew: false,
            hasClickEdit: false,
            modal: false,
            type: "",

            // contiene la lista dei country con fake VPN
            getVpnSettingList: {},
            updateCountry: {},
            deleteCountry: ""
        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare vpn setting, viene rispedito alla home
        if (!isEmpty(this.props.role.preferences)) {
            if (this.props.user.user.role !== SUPER_ADMIN) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare Vpn Setting!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getVpnSettingList();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare vpn setting, viene rispedito alla home
        if (!isEmpty(this.props.role.preferences)) {
            if (this.props.user.user.role !== SUPER_ADMIN) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare Vpn Setting!')

            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getVpnSettingList();
                }
            }
        }

        if (!localStorage.token) {
            this.props.handleNavigation(this.props.navigate, '/');
        }
    }

    /**
     * Chiama il BE e ritorna la lista dei paesi con fake VPN
     * 
     */
    getVpnSettingList = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await axiosInstance.get(`${API_PREFERENCES}/${VPN_SETTING_PROXY}`);
            this.setState({
                getVpnSettingList: response.data,
                isLoading: false
            });

        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });

            if (result && result.response && result.response.status) {
                switch (result.response) {
                    default: errorNotify(`Error ${result.response.status} su ${API_PREFERENCES} - GET`); break;
                }
            }
        }
    }

    /**
     * Aggiungi alla lista una fake VPN
     * 
     */
    hasClickNew = (type) => {
        this.setState({
            hasClickNew: true,
            type: type
        });


        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 1000);
    }

    /**
     * Rimuove il paese dalla lista fake VPN
     * 
     */
    hasClickEdit = (country, type) => {
        this.setState({
            hasClickEdit: true,
            type: type,
            deleteCountry: country.toString(),

            updateCountry: {
                [country]: {
                    isEnabled: false
                }
            }
        });

        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 1000);
    }

    /**
     * Invio la modifica della lista dei vpn setting
     */
    sendEditVpnSettingList = (id, data) => {
        if (this.state.type === "remove") {
            this.props.deleteKeyFromList(id, this.state.deleteCountry);

        } else {
            this.props.sendEditPreferences(id, data);
        }

        this.toggleModal();

        setTimeout(() => {
            this.getVpnSettingList();
        }, 1500);
    }

    /**
     * Usato solo per chiudere la modale, poiche resetta tutto lo state
     */
    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));

        this.setState({
            hasClickNew: false,
            hasClickEdit: false,
            updateCountry: {},
            deleteCountry: "",
            type: ""
        });
    }

    render() {

        return (
            <Fragment>
                <div className="row">
                    <div className="col-12 vpnDesktop">
                        {(this.state.isLoading || this.state.loadingForRole) && <Loading />}

                        <div className="contentUser">
                            <div className="row titleMobile">
                                <div className="col">
                                    <h2 className='inlineBlock'>VPN Setting</h2>
                                </div>
                            </div>

                            <div className="row" style={{ marginBottom: '2rem' }}>
                                <div className="col-12 text-center">
                                    <button className="btn btn-success" onClick={() => { this.hasClickNew("new") }}>
                                        <i className="fas fa-plus" style={{ 'marginRight': '5px' }} /> Aggiungi VPN
                                    </button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="contentCountryList">
                                        <div className="row title">
                                            <div className="col">
                                                Nazione
                                            </div>

                                            <div className="col-3 text-center">
                                                Sigla
                                            </div>

                                            <div className="col text-center">
                                                Enabled
                                            </div>

                                            <div className="col-3">
                                            </div>
                                        </div>
                                        {
                                            Object.keys(this.state.getVpnSettingList).map(el => {
                                                return (
                                                    <div className="row whiteRow" key={el}>
                                                        <div className="col">
                                                            {el === 'XX' ? '--' : returnNationality(el)}
                                                        </div>

                                                        <div className="col-3 text-center">
                                                            {el}
                                                        </div>

                                                        <div className="col text-center">
                                                            {
                                                                this.state.getVpnSettingList[el].isEnabled ?
                                                                    "true"
                                                                    :
                                                                    "false"
                                                            }
                                                        </div>

                                                        <div className="col-3">
                                                            {
                                                                this.props.user.user.role === SUPER_ADMIN &&
                                                                <button className="btnDelete" onClick={() => { this.hasClickEdit(el, "remove") }}>
                                                                    <i className="fas fa-trash-alt"></i> Rimuovi
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    (this.state.hasClickNew || this.state.hasClickEdit) &&
                    <ModalVpnSetting
                        titleModal={this.state.type === "remove" ? "Rimuovi Nazione" : "Aggiungi Nazione"}

                        sendEditVpnSettingList={this.sendEditVpnSettingList}
                        updateCountry={this.state.updateCountry}
                        type={this.state.type}

                        toggle={this.toggleModal}

                        stateModal={this.state.modal}
                        errorNotify={this.props.errorNotify}
                    />
                }
            </Fragment>
        )
    }
}

export default withNavigate(vpnSetting);