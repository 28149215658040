import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';

import './style.scss';
import {
    API_VPN_MACHINE_LIST,
    API_VPN_ADD_MACHINE,
    API_VPN_EDIT_MACHINE,
    API_VPN_PUBLISH,
    API_VPN_UNPUBLISH,
    API_VPN_DELETE_MACHINE,
    API_VPN_SETUP_MACHINE,
    API_VPN_STOP_MACHINE,
    API_VPN_START_MACHINE,
    TIME_POLLING_VPN_MAHCINE
} from '../../config';
import FormFilter from '../components/formFiltersMachine';
import Loading from '../../common/loading';
import { returnNationality } from '../../common/actions/returnNationality';
import { randomString } from '../../common/actions/randomString';
import ModalVpnMachine from '../../common/modal/modalVpnMachine';
import Modal from '../../common/modal';
import ExpanableComponent from '../components/expanableComponentMachine';
import ActionMenu from '../components/actionMenuMachine';
import CSVExport from '../../common/csvExport';
import { controlRole } from '../../common/actions/controlRole';
import { isEmpty, withNavigate } from '../../utility';
import axiosInstance from '../../axiosInstance';

class vpnMachine extends Component {
    timeout = 0;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            // contiene la lista di tutte le vpn
            getVpnMachineList: [],

            // numero di pagine vpn che ci sono nel DB
            totalPages: '',

            // numero di pagina selezionata
            numbersPage: [],

            // quante vpn vuoi visualizzare
            numberVpnMachine: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // variabili di controllo per modali
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: false,
            hasClickStopMachine: false,
            hasClickStartMachine: false,
            hasClickPublish: false,
            hasClickUnPublish: false,

            titleModal: '',
            contentModal: '',
            modalVpn: false,
            modal: false,
            openFilters: true,
            openFiltersDesktop: false,

            // variabile temporanea per trasportare i dati da editare di una vpn
            vpnTempEdit: {},

            // usato per i filtri della ricerca
            paramsForSearch: {},

            shortId: randomString(),

            loadingForRole: true
        }
        this.openMenu = this.openMenu.bind(this);
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare la vpnMachine, viene rispedito alla home
        if (!isEmpty(this.props.role.vpnMachine)) {
            if (!controlRole(this.props.role.vpnMachine, "api/VpnMachine", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le VPN Machine!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
            }
        }
        this.getVpnMachineList();
        this.startPolling();
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare la vpnMachine, viene rispedito alla home
        if (!isEmpty(this.props.role.vpnMachine)) {
            if (!controlRole(this.props.role.vpnMachine, "api/VpnMachine", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le VPN Machine!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
            }
        }

        if (!localStorage.token) {
            this.props.handleNavigation(this.props.navigate, '/');
        }
    }

    componentWillUnmount() {
        this.stopPolling();
    }

    startPolling = () => {
        this.timeout = setInterval(() => {
            this.getVpnMachineList(true);
        }, TIME_POLLING_VPN_MAHCINE);
    }

    stopPolling = () => {
        clearInterval(this.timeout);
    }

    setLoading = (value) => {
        this.setState({ isLoading: value });
    }

    /**
     * Usato per settare le modali che vengono aperte nelle componenti figlie.
     * Questo perche quando faccio polling, alla chiamata del backend vengono chiuse le modali,
     * in questo modo rimangono aperte.
     */
    setStatusModalChild = () => {
        this.setState({ hasClickModal: true });
    }

    /**
     * Questa funzione viene richiamata ogni 10 secondi.
     * 
     * Prende un array di oggetti che è stato restituito dal BE, contenente tutte le informazioni sulle macchine vpn,
     * verifica se alcune macchine vpn non sono piu online e modifica solo l'informazione relativa alla status online della macchina.
     * 
     */
    getVpnMachineList = async (changeNumberPage) => {
        try {
            let params = {
                'elementsByPage': this.state.numberVpnMachine,
                'page': changeNumberPage ? this.state.currentNumberPage : 1,
                'sortField': this.state.paramsForSearch.fieldOrder,
                'sortType': this.state.paramsForSearch.typeOrder,
                'machineFilters[0][Proxy]': isEmpty(this.state.paramsForSearch) ? 0 : this.state.paramsForSearch.isProxy,
                'machineFilters[1][MachineId]': this.state.paramsForSearch.id,
                'machineFilters[2][Mantainer]': this.state.paramsForSearch.mantainer,
                'machineFilters[3][CreatedAtStart]': this.state.paramsForSearch.createdStartAt === null ? '' : this.state.paramsForSearch.createdStartAt,
                'machineFilters[4][CreatedAtEnd]': this.state.paramsForSearch.createdEndAt === null ? '' : this.state.paramsForSearch.createdEndAt,
                'machineFilters[5][UpdateAtStart]': this.state.paramsForSearch.updateStartAt === null ? '' : this.state.paramsForSearch.updateStartAt,
                'machineFilters[6][UpdateAtEnd]': this.state.paramsForSearch.updateEndAt === null ? '' : this.state.paramsForSearch.updateEndAt,
                'machineFilters[7][Country]': this.state.paramsForSearch.country,
                'machineFilters[8][Os]': this.state.paramsForSearch.os,
                'machineFilters[9][City]': this.state.paramsForSearch.city,
                'machineFilters[10][Model]': this.state.paramsForSearch.model,
                'machineFilters[11][CpuDescription]': this.state.paramsForSearch.cpuDescription,
                'machineFilters[12][MachineStatus]': this.state.paramsForSearch.status
            };

            let response = await axiosInstance.get(`${API_VPN_MACHINE_LIST}`, { params: params });

            if (!changeNumberPage) {
                this.setState({
                    currentNumberPage: 1
                });
            }

            let arrayTemp = [];

            // eslint-disable-next-line
            response.data.value.value.map((item) => {
                arrayTemp.push({
                    key: item.key,
                    networkInterfaceName: item.networkInterfaceName,
                    maxBandwidth: item.maxBandwidth,
                    mantainer: item.mantainer,
                    country: item.country,
                    city: item.city,
                    model: item.model,
                    os: item.os,
                    cpuDescription: item.cpuDescription,
                    publicKey: item.publicKey,
                    privateKey: item.privateKey,
                    cpuCores: item.cpuCores,
                    peerNumber: item.peerNumber,
                    createdAt: item.createdAt,
                    updatedAt: item.updatedAt,
                    credentials: item.credentials,
                    status: (item.isOnline && item.status === 1) ? 2 : item.status, //se la macchina è online ed è 'installing', allora lo status deve essere 'Started' => (2)
                    cpu: item.cpu,
                    memory: item.memory,
                    rx: item.rx,
                    tx: item.tx,
                    formula: item.formula,
                    score: item.score,
                    isProxy: item.isProxy,
                    serverVersion: item.serverVersion
                });
            });

            this.setState({
                getVpnMachineList: arrayTemp,
                totalPages: response.data.info.totalPages,
                isLoading: false
            });

            this.props.setNumberVpnMachine(response.data.info.total);
            this.setNumbersPage(response.data.info.totalPages);

        } catch (result) {
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Errore ' + result.response.status + ' su GET_VPN_MACHINE');
                this.setState({ isLoading: false });
            }
        }
    }

    /**
     * In base alla risposta del server, 
     * setta il numero delle pagine disponibili per la paginazione
     */
    setNumbersPage = (numbersPage) => {
        let temp = [];
        for (let i = 1; i <= parseInt(numbersPage); i++) {
            temp.push(i);
        }
        this.setState({ numbersPage: temp });
    }

    /**
     * Setta il numero di pagina selezionato dall'utente
     * o il numero di elementi da visualizzare, richiamando il BE.
     * 
     * @param {*} event 
     * @param {*} newItem 
     */
    setChangePagination = (event, newItem) => {
        if (event.target.name === 'numberVpnMachine') {
            this.setState({
                isLoading: true,
                numberVpnMachine: newItem
            });
        }
        else if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                isLoading: true,
                currentNumberPage: newItem,
            });
        }
        setTimeout(() => {
            this.getVpnMachineList(true);
        }, 100);
    }

    /**
     * Setta l'oggetto che contiene i filtri per la ricerca
     * 
     * @param {*} data contengono i filtri per la ricerca
     */
    setParamsForSearch = (data) => {
        this.setState({
            isLoading: true,
            paramsForSearch: data
        });

        setTimeout(() => {
            this.getVpnMachineList();
        }, 100);
    }

    /**
     * Se clicca aggiungi nuova VPN machine, attiva la modale
     */
    hasClickAddVpnMachine = () => {
        this.stopPolling();
        this.setState({
            hasClickAddVpnMachine: true,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: false,
            hasClickStopMachine: false,
            hasClickStartMachine: false,
            hasClickPublish: false,
            hasClickUnPublish: false,
            titleModal: "Aggiungi VPN Machine",
            contentModal: '',
            shortId: randomString()
        });
        setTimeout(() => {
            this.setState({ modalVpn: true });
        }, 200);
    }

    /**
     * Se clicca edita VPN machine, attiva la modale
     */
    hasClickEditVpnMachine = (data) => {
        this.stopPolling();
        this.setState({
            vpnTempEdit: data,
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: true,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: false,
            hasClickStopMachine: false,
            hasClickStartMachine: false,
            hasClickPublish: false,
            hasClickUnPublish: false,
            titleModal: "Modifica VPN Machine",
            contentModal: '',
            shortId: randomString()
        });
        setTimeout(() => {
            this.setState({ modalVpn: true });
        }, 200);
    }

    /**
     * Se viene cliccato il delete di una vpn machine,
     * viene azionata una modale generale che chiede solo conferma.
     * 
     * @param {*} data della vpn machine
     */
    hasClickRemoveMachine = (data) => {
        this.stopPolling();
        this.setState({
            vpnTempEdit: data,
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: true,
            hasClickSetupMachine: false,
            hasClickStopMachine: false,
            hasClickStartMachine: false,
            hasClickPublish: false,
            hasClickUnPublish: false,
            titleModal: "Rimuovi VPN Machine",
            contentModal: 'Sei sicuro di voler eliminare la VPN con ID:  ' + data.key + ' ?',
            shortId: randomString()
        });
        setTimeout(() => {
            this.setState({ modal: true });
        }, 200);
    }

    /**
     * Se viene cliccato il delete di una vpn machine,
     * viene azionata una modale generale che chiede solo conferma.
     * 
     * @param {*} data della vpn machine
     */
    hasClickSetupMachine = (data) => {
        this.stopPolling();
        this.setState({
            vpnTempEdit: data,
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: true,
            hasClickStopMachine: false,
            hasClickStartMachine: false,
            hasClickPublish: false,
            hasClickUnPublish: false,
            titleModal: "Setup VPN Machine",
            contentModal: 'Sei sicuro di voler avviare la VPN con ID:  ' + data.key + ' ?',
            shortId: randomString()
        });
        setTimeout(() => {
            this.setState({ modal: true });
        }, 200);
    }

    /**
     * Se viene cliccato lo stop di una vpn machine,
     * viene azionata una modale generale che chiede solo conferma.
     * 
     * @param {*} data della vpn machine
     */
    hasClickStopMachine = (data) => {
        this.stopPolling();
        this.setState({
            vpnTempEdit: data,
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: false,
            hasClickStopMachine: true,
            hasClickStartMachine: false,
            hasClickPublish: false,
            hasClickUnPublish: false,
            titleModal: "Stop VPN Machine",
            contentModal: 'Sei sicuro di voler fermare la VPN con ID:  ' + data.key + ' ?',
            shortId: randomString()
        });
        setTimeout(() => {
            this.setState({ modal: true });
        }, 200);
    }

    /**
     * Se viene cliccato lo start di una vpn machine,
     * viene azionata una modale generale che chiede solo conferma.
     * 
     * @param {*} data della vpn machine
     */
    hasClickStartMachine = (data) => {
        this.stopPolling();
        this.setState({
            vpnTempEdit: data,
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: false,
            hasClickStopMachine: false,
            hasClickStartMachine: true,
            hasClickPublish: false,
            hasClickUnPublish: false,
            titleModal: "Start VPN Machine",
            contentModal: 'Sei sicuro di voler start-are la VPN con ID:  ' + data.key + ' ?',
            shortId: randomString()
        });
        setTimeout(() => {
            this.setState({ modal: true });
        }, 200);
    }

    /**
     * Se viene cliccato published di una vpn machine,
     * viene azionata una modale generale che chiede solo conferma.
     * 
     * @param {*} data della vpn machine
     */
    hasClickPublishMachine = (data) => {
        this.stopPolling();
        this.setState({
            vpnTempEdit: data,
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: false,
            hasClickStopMachine: false,
            hasClickStartMachine: false,
            hasClickPublish: true,
            hasClickUnPublish: false,
            titleModal: "Publish VPN Machine",
            contentModal: 'Sei sicuro di voler publicare la VPN con ID:  ' + data.key + ' ?',
            shortId: randomString()
        });
        setTimeout(() => {
            this.setState({ modal: true });
        }, 200);
    }

    /**
     * Se viene cliccato published di una vpn machine,
     * viene azionata una modale generale che chiede solo conferma.
     * 
     * @param {*} data della vpn machine
     */
    hasClickUnPublishMachine = (data) => {
        this.stopPolling();
        this.setState({
            vpnTempEdit: data,
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: false,
            hasClickStopMachine: false,
            hasClickStartMachine: false,
            hasClickPublish: false,
            hasClickUnPublish: true,
            titleModal: "UnPublish VPN Machine",
            contentModal: 'Sei sicuro di voler rimuovere la VPN con ID:  ' + data.key + ' ?',
            shortId: randomString()
        });
        setTimeout(() => {
            this.setState({ modal: true });
        }, 200);
    }

    /**
     * Chiude le modali per la gestione della VPN machine e generale, resettando i valori
     */
    toggleModal = () => {
        this.setState({ modalVpn: false });
        this.setState({ modal: false });
        this.setState({
            hasClickAddVpnMachine: false,
            hasClickEditVpnMachine: false,
            hasClickRemoveMachine: false,
            hasClickSetupMachine: false,
            hasClickStopMachine: false,
            hasClickStartMachine: false,
            hasClickPublish: false,
            hasClickUnPublish: false,
            vpnTempEdit: {}
        });
        this.getVpnMachineList(true);
        this.startPolling();
    }

    /**
     * Chiama il BE, invia i parametri per la creazione di una nuova vpn.
     * Dopodiche chiude la modale e aggiorna la lista delle vpn inserendo la nuova in lista.
     */
    addVpnMachine = (data) => {
        let that = this;
        let thatProps = this.props;

        that.setState({ isLoading: true });
        axiosInstance.post(`${API_VPN_ADD_MACHINE}`, data)
            .then(function () {
                thatProps.successNotify('VPN Machine aggiunta con successo!');
                that.toggleModal();
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_ADD_MACHINE} - POST`);
            });
    }

    /**
     * Chiama il BE, invia i parametri per la modifica di una vpn.
     * Dopodiche chiude la modale e aggiorna la lista delle vpn modificando la lista.
     */
    editVpnMachine = (data) => {
        let that = this;
        let thatProps = this.props;

        that.setState({ isLoading: true });
        axiosInstance.put(`${API_VPN_EDIT_MACHINE}/${data.key}`, data)
            .then(function () {
                thatProps.successNotify('VPN Machine modificata con successo!');
                that.toggleModal();
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('VPN machine non trovata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_EDIT_MACHINE} - PUT`); break;
                }
            });
    }

    /**
     * Chiama il BE e publica una machine in status Started.
     * NOTA: chiamata che deve essere fatta solo quando la macchina è Started.
     */
    publishVpnMachine = (data) => {
        let that = this;
        let thatProps = this.props;
        that.setState({ isLoading: true });

        axiosInstance.put(`${API_VPN_PUBLISH}/${data}`)
            .then(function () {
                thatProps.successNotify('VPN Machine publicata con successo!');
                that.toggleModal();
                that.setState({ isLoading: false });
            })

            .catch(function (result) {
                that.setState({ isLoading: false });
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('VPN machine non trovata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_PUBLISH} - PUT`); break;
                }
            });
    }

    /**
     * Chiama il BE e rimuove dalla publicazione una machine in status Published.
     * NOTA: chiamata che deve essere fatta solo quando la macchina è Published.
     */
    unPublishVpnMachine = (data) => {
        let that = this;
        let thatProps = this.props;
        that.setState({ isLoading: true });

        axiosInstance.put(`${API_VPN_UNPUBLISH}/${data}`)
            .then(function () {
                thatProps.successNotify('VPN Machine rimossa con successo!');
                that.toggleModal();
                that.setState({ isLoading: false });
            })

            .catch(function (result) {
                that.setState({ isLoading: false });
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('VPN machine non trovata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_UNPUBLISH} - PUT`); break;
                }
            });
    }

    /**
     * Chiama il BE, invia l'id per la rimozione di una vpn.
     * Dopodiche chiude la modale e aggiorna la lista delle vpn rimuovendo quella in precedenza dalla lista.
     */
    removeVpnMachine = (id) => {
        let that = this;
        let thatProps = this.props;

        that.setState({ isLoading: true });
        axiosInstance.delete(`${API_VPN_DELETE_MACHINE}/${id}`)
            .then(function () {
                thatProps.successNotify('VPN Machine rimossa con successo!');
                that.toggleModal();
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('VPN machine non trovata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_DELETE_MACHINE} - DELETE`); break;
                }
            });
    }

    /**
     * Chiama il BE, invia l'id per il setup di una vpn.
     * Dopodiche chiude la modale e aggiorna la lista delle vpn.
     */
    setupVpnMachine = (id) => {
        let that = this;
        let thatProps = this.props;

        that.setState({ isLoading: true });
        axiosInstance.post(`${API_VPN_SETUP_MACHINE}/${id}`)
            .then(function () {
                thatProps.successNotify('VPN Machine setup-pata con successo!');
                that.toggleModal();
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('VPN machine non setup-pata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_SETUP_MACHINE} - POST`); break;
                }
            });
    }

    /**
     * Chiama il BE, invia l'id e i parametri per lo start di una vpn machine.
     * Dopodiche chiude la modale e aggiorna la lista delle vpn machine.
     */
    startVpnMachine = (id) => {
        let that = this;
        let thatProps = this.props;

        this.setState({ isLoading: true });

        axiosInstance.get(`${API_VPN_START_MACHINE}/${id}`)
            .then(function () {
                thatProps.successNotify('VPN Machine start-ata con successo!');
                that.toggleModal();
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('VPN machine non start-ata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_START_MACHINE} - GET`); break;
                }
            });
    }

    /**
     * Chiama il BE, invia l'id e i parametri per lo stop di una vpn machine.
     * Dopodiche chiude la modale e aggiorna la lista delle vpn machine.
     */
    stopVpnMachine = (id) => {
        let that = this;
        let thatProps = this.props;
        this.setState({ isLoading: true });

        axiosInstance.get(`${API_VPN_STOP_MACHINE}/${id}`)
            .then(function () {
                thatProps.successNotify('VPN Machine stop-pata con successo!');
                that.toggleModal();
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('VPN machine non stop-pata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_STOP_MACHINE} - GET`); break;
                }
            });
    }

    /**
     * Quando utilizzo una modale, viene attivata la funzione per quella determinata modale.
     * 
     * @param data
     */
    actionModal = (data) => {
        if (this.state.hasClickAddVpnMachine) {
            this.addVpnMachine(data);
        }
        if (this.state.hasClickEditVpnMachine) {
            this.editVpnMachine(data);
        }
        if (this.state.hasClickRemoveMachine) {
            this.removeVpnMachine(this.state.vpnTempEdit.key);
        }
        if (this.state.hasClickSetupMachine) {
            this.setupVpnMachine(this.state.vpnTempEdit.key);
        }
        if (this.state.hasClickStopMachine) {
            this.stopVpnMachine(this.state.vpnTempEdit.key);
        }
        if (this.state.hasClickStartMachine) {
            this.startVpnMachine(this.state.vpnTempEdit.key);
        }
        if (this.state.hasClickPublish) {
            this.publishVpnMachine(this.state.vpnTempEdit.key);
        }
        if (this.state.hasClickUnPublish) {
            this.unPublishVpnMachine(this.state.vpnTempEdit.key);
        }
    }

    /**
     * In base al click dell'azione dal menu della vpn, effettua una determinata funzione.
     * @param {*} data 
     */
    actionClick = (action, data) => {
        switch (action) {
            case 'edit': this.hasClickEditVpnMachine(data); break;
            case 'setup': this.hasClickSetupMachine(data); break;
            case 'remove': this.hasClickRemoveMachine(data); break;
            case 'start': this.hasClickStartMachine(data); break;
            case 'stop': this.hasClickStopMachine(data); break;
            case 'publish': this.hasClickPublishMachine(data); break;
            case 'unPublish': this.hasClickUnPublishMachine(data); break;
            default: break;
        }
    }

    /**
     * Restituisce l'icona colorata in base allo status.
     * Definisco se la macchina sia online o meno tramite icona verde o rossa.
     * 
     * @param {Boolean} status 
     */
    statusOnline = (status) => {
        if (status) {
            return <i className="fas fa-circle green"></i>;
        } else {
            return <i className="fas fa-circle red"></i>
        }
    }

    /**
     * Restituisce la label corretta in base allo status della macchina su redis.
     * 
     * @param {String} status 
     */
    statusLabelDefine = (status) => {
        // eslint-disable-next-line
        switch (status) {
            case -2: case '-2': return 'Failed';
            case -1: case '-1': return 'Suspended';
            case 0: case '0': return 'Created';
            case 1: case '1': return 'Installing';
            case 2: case '2': return 'Started';
            case 3: case '3': return 'Published';
        }
    }

    /**
     * Restituisce la classe corretta in base allo status della macchina su redis.
     * 
     * @param {String} status 
     */
    statusClassDefine = (status) => {
        // eslint-disable-next-line
        switch (status) {
            case -2: case '-2': return 'red';
            case -1: case '-1': return 'black';
            case 0: case '0': return 'orange';
            case 1: case '1': return 'yellow';
            case 2: case '2': return 'blue';
            case 3: case '3': return 'green';
        }
    }

    openMenu = () => {
        this.setState(prevState => ({ openFilters: !prevState.openFilters }));
    }

    openMenuDesktop = () => {
        this.setState(prevState => ({ openFiltersDesktop: !prevState.openFiltersDesktop }));
    }

    render() {
        const { role } = this.props;

        const columns = [
            {
                name: 'Type',
                selector: row => row.isProxy ? <div className='typeBox blue'>Proxy</div> : <div className='typePass orange'>VPN</div>,
                maxWidth: '80px'
            },
            {
                name: 'Status',
                selector: row => <div className={this.statusClassDefine(row.status)}>{this.statusLabelDefine(row.status)}</div>,
                maxWidth: '100px'
            },
            {
                name: 'Country',
                selector: row => <>{<img src={`${process.env.PUBLIC_URL}/flags/${row.country}.svg`} alt='err_img' />} <span>{returnNationality(row.country)}</span></>,
            },
            {
                name: 'Machine ID',
                selector: row => row.key
            },
            {
                name: 'Host',
                selector: row => row.credentials.host,
                maxWidth: '150px'
            },
            {
                name: 'Score',
                selector: row => row.score
            },
            {
                name: 'Max bandwith',
                selector: row => row.maxBandwidth
            },
            {
                name: 'Core',
                selector: row => row.cpuCores
            },
            {
                name: 'Peer',
                selector: row => row.peerNumber
            },
            {
                selector: row =>
                    (row.status === 1 || row.status === '1') ? '' :
                        <ActionMenu
                            actionClick={this.actionClick}
                            vpn={row}
                            data={this.state.getVpnMachineList}
                            role={role}
                            setStatusModalChild={this.setStatusModalChild}
                            handleNavigation={this.props.handleNavigation}
                        />,
                minWidth: '80px',
                maxWidth: '80px',
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {
                    this.state.loadingForRole ? <Loading /> :
                        <Fragment>
                            <div className="row">
                                <div className="col-12 vpnDesktop">

                                    {(this.props.vpn.isLoading || this.state.isLoading) && <Loading />}

                                    <div className="contentFilterSearch">
                                        <FormFilter
                                            setLoading={this.setLoading}
                                            totalPages={this.state.totalPages}
                                            currentNumberPage={this.state.currentNumberPage}
                                            numbersPage={this.state.numbersPage}
                                            numberVpnMachine={this.state.numberVpnMachine}
                                            setParamsForSearch={this.setParamsForSearch}
                                            setChangePagination={this.setChangePagination}

                                            warningNotify={this.props.warningNotify}
                                            errorNotify={this.props.errorNotify}
                                            infoNotify={this.props.infoNotify}
                                            successNotify={this.props.successNotify}
                                        />
                                    </div>

                                    <div className="contentUser">
                                        <div className="row titleMobile">
                                            <div className="col-6">
                                                <h2 className='inlineBlock'>VPN Machine</h2>
                                                {
                                                    this.props.vpn.totalVpnMachine &&
                                                    <div className="mybadge">
                                                        {this.props.vpn.totalVpnMachine}
                                                    </div>
                                                }
                                            </div>

                                            <div className="col-6 text-end">
                                                <CSVExport
                                                    data={this.state.getVpnMachineList}
                                                    filename="list-machines.csv"
                                                    label="Scarica la lista deelle machines"
                                                />
                                            </div>

                                            <div className="col-12 text-center">
                                                {
                                                    controlRole(role.vpnMachine, "api/VpnMachine/Add", "POST") &&
                                                    <button className="btn btn-success addMarginForBtn" onClick={this.hasClickAddVpnMachine}>
                                                        <i className="fas fa-plus" style={{ 'marginRight': '5px' }}></i>
                                                        Aggiungi VPN Machine
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <DataTable
                                                    columns={columns}
                                                    data={this.state.getVpnMachineList}
                                                    customStyles={customStyles}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    expandableRows={this.state.isLoading ? false : true}
                                                    expandOnRowClicked
                                                    expandableRowsComponent={ExpanableComponent}
                                                    expandableRowsComponentProps={{
                                                        "clickMoreDetail": this.actionClick,
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            {
                                (this.state.hasClickAddVpnMachine || this.state.hasClickEditVpnMachine) &&
                                <ModalVpnMachine
                                    click={this.actionModal}
                                    vpnTempEdit={this.state.vpnTempEdit}

                                    successNotify={this.props.successNotify}
                                    errorNotify={this.props.errorNotify}
                                    warningNotify={this.props.warningNotify}

                                    titleModal={this.state.titleModal}
                                    stateModal={this.state.modalVpn}
                                    toggle={this.toggleModal}
                                    key={this.state.shortId}
                                />
                            }

                            {
                                (
                                    this.state.hasClickSetupMachine ||
                                    this.state.hasClickRemoveMachine ||
                                    this.state.hasClickStopMachine ||
                                    this.state.hasClickStartMachine ||
                                    this.state.hasClickPublish ||
                                    this.state.hasClickUnPublish
                                ) &&
                                <Modal
                                    click={this.actionModal}
                                    titleModal={this.state.titleModal}
                                    contentModal={this.state.contentModal}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default withNavigate(vpnMachine);