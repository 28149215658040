import React, { Component, Fragment } from 'react';
import moment from 'moment';

import './style.scss';
import ModalChangeLog from '../../common/modal/modalChangeLog';
import Modal from '../../common/modal';
import Loading from '../../common/loading';
import { randomString } from '../../common/actions/randomString';
import { API_CHANGELOG, API_DELETE_CHANGELOG, MAINTENANCE } from '../../config';
import { controlRole } from '../../common/actions/controlRole';
import { isEmpty, withNavigate } from '../../utility';
import axiosInstance from '../../axiosInstance';

class ChangeLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loadingForRole: true,

            // titolo del prodotto
            typeChangeLog: '',

            // contiene la lista di tutti i changeLog
            getChangeLog: {},

            dateVersion: null,

            shortId: randomString(),

            // variabili per modali
            titleModal: '',
            contentModal: '',
            modalChangeLog: false,
            modal: false,

            hasClickNewChangeLog: false,
            hasClickEditChangeLog: false,
            hasClickNewVersionChangeLog: false,
            hasClickDeleteChangeLog: false,
            hasClickDeleteVersionChangeLog: false,

            // versione del prodotto selezionata dall'utente
            versionTemp: '',

            inMaintenance: false,

            // logs della versione del prodotto selezionata dall'utente
            logsIt: '',
            logsEn: '',
            logsEs: '',
            logsDe: '',
            logsFr: ''
        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare i changelog, viene rispedito alla home
        if (!isEmpty(this.props.role.changeLog)) {
            if (!controlRole(this.props.role.changeLog, "api/Changelog", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le changelog!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getChangeLog();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare i changelog, viene rispedito alla home
        if (!isEmpty(this.props.role.changeLog)) {
            if (!controlRole(this.props.role.changeLog, "api/Changelog", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le changelog!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    this.getChangeLog();
                }
            }
        }

        if (!localStorage.token) {
            this.props.handleNavigation(this.props.navigate, '/');
        }
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    getChangeLog = async () => {
        this.setState({
            isLoading: true,
            inMaintenance: false
        });
        try {
            let response = await axiosInstance.get(API_CHANGELOG);
            this.setState({
                getChangeLog: response.data,
                typeChangeLog: Object.keys(response.data)[0],
                isLoading: false
            });
            // eslint-disable-next-line
            Object.keys(response.data).map(el => {
                if (el === MAINTENANCE) this.setState({ inMaintenance: true })
            })
        } catch (result) {
            const { errorNotify } = this.props;
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                switch (result.response) {
                    default: errorNotify(`Error ${result.response.status} su ${API_CHANGELOG} - GET`); break;
                }
            }
        }
    }

    toggleModal = () => {
        this.setState({
            modalChangeLog: false,
            modal: false,
            hasClickNewChangeLog: false,
            hasClickEditChangeLog: false,
            hasClickDeleteChangeLog: false,
            hasClickDeleteVersionChangeLog: false,
            hasClickNewVersionChangeLog: false,
            titleModal: '',
            contentModal: '',
            versionTemp: '',
            logsIt: '',
            logsEn: '',
            logsEs: '',
            logsDe: '',
            logsFr: '',
            dateVersion: null,
            shortId: randomString(),
        })
    }

    /**
     * Apre la modale per la creazione di un nuovo prodotto per changelog.
     */
    hasClickNewChangeLog = () => {
        this.setState({
            hasClickNewChangeLog: true,
            titleModal: 'Nuovo Prodotto',
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalChangeLog: !prevState.modalChangeLog }));
        }, 100);
    }

    /**
     * Apre la modale per la creazione di una nuova versione per un prodotto di changelog.
     */
    hasClickNewVersionChangeLog = () => {
        this.setState({
            hasClickNewVersionChangeLog: true,
            titleModal: 'Aggiungi Versione a ' + this.state.typeChangeLog,
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalChangeLog: !prevState.modalChangeLog }));
        }, 100);
    }

    /**
     * Apre la modale per la modifica di una nuova versione per un prodotto di changelog.
     * 
     * @param version
     * @param date
     * @param logs
     */
    hasClickEditChangeLog = (version, logs, date) => {
        this.setState({
            hasClickEditChangeLog: true,
            versionTemp: version,
            dateVersion: date,
            logsIt: logs[`it-IT`],
            logsEn: logs[`en-GB`],
            logsEs: logs[`es-SP`],
            logsDe: logs[`de-DE`],
            logsFr: logs[`fr-FR`],
            titleModal: 'Modifica Log alla versione ' + version,
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalChangeLog: !prevState.modalChangeLog }));
        }, 100);
    }

    /**
     * Apre la modale per l'eliminazione di una versione da un prodotto.
     */
    hasClickDeleteVersionChangeLog = (version) => {
        this.setState({
            hasClickDeleteVersionChangeLog: true,
            versionTemp: version,
            titleModal: 'Elimina Versione da ' + this.state.typeChangeLog,
            contentModal: 'Sei sicuro di voler eliminare la versione ' + version + ' da ' + this.state.typeChangeLog + ' ?',
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Apre la modale per l'eliminazione di un prodotto changelog.
     */
    hasClickDeleteChangeLog = (el) => {
        this.setState({
            hasClickDeleteChangeLog: true,
            typeChangeLog: el,
            titleModal: 'Elimina Prodotto dai ChangeLog',
            contentModal: 'Sei sicuro di voler eliminare il prodotto ' + el + ' e tutte le sue versioni associate?',
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 100);
    }

    /**
     * Crea un nuovo prodotto per le versioni.
     * 
     * @param {*} name è il nome del nuovo prodotto
     */
    newChangeLog = (name) => {
        this.setState({ isLoading: true });
        try {
            let params = {
                "productName": name,
                "version": "0.0.1",
                "createdAt": moment(),
                "logLanguage": {
                    "it-IT": "Primo commit",
                    "en-GB": "First commit",
                    "es-SP": "Primer compromiso",
                    "de-DE": "Erstes commit",
                    "fr-FR": "Premier commit",
                }
            };
            let that = this;
            axiosInstance.put(API_CHANGELOG, params)
                .then(function () {
                    that.getChangeLog();
                    that.props.successNotify('Prodotto aggiunto con successo!');
                    that.setState({ isLoading: false });
                });

        } catch (result) {
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Prodotto non aggiunto. Errore: ' + result.response.status);
            }
        }
    }

    /**
     * Modifica una versione per un prodotto già esistente.
     * 
     * @param {Object} data contiene productName, version, logs
     */
    editVersionLog = (data) => {
        this.setState({ isLoading: true });
        try {
            let that = this;
            axiosInstance.put(API_CHANGELOG, data)
                .then(function () {
                    that.getChangeLog();
                    that.props.successNotify('Versione aggiornata con successo!');
                    that.setState({ isLoading: false });
                });

        } catch (result) {
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Versione non aggiornata. Errore ' + result.response.status);
            }
        }
    }

    /**
     * Modifica una versione per un warning già esistente.
     * Cancello la versione che c'è e inserisco la nuova versione.
     * 
     * @param {Object} data
     */
    editWarning = async (data) => {
        this.setState({ isLoading: true });
        try {
            let that = this;
            await axiosInstance.delete(`${API_CHANGELOG}?productName=${this.state.typeChangeLog}&version=${data.oldVersion}`);
            await axiosInstance.put(API_CHANGELOG, data)
                .then(function () {
                    that.getChangeLog();
                    that.props.successNotify('Warning aggiornato con successo!');
                    that.setState({ isLoading: false });
                });


        } catch (result) {
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Versione non aggiornata. Errore ' + result.response.status);
            }
        }
    }

    /**
     * Elimina una versione da un prodotto.
     * 
     */
    deleteVersionChangeLog = () => {
        this.setState({ isLoading: true });
        try {

            let that = this;
            axiosInstance.delete(`${API_CHANGELOG}?productName=${this.state.typeChangeLog}&version=${this.state.versionTemp}`)
                .then(function () {
                    that.getChangeLog();
                    that.props.successNotify('Versione rimossa con successo!');
                    that.setState({ isLoading: false });
                });

        } catch (result) {
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Errore ' + result.response.status + ' su /api/Changelog - DELETE');
            }
        }
    }

    /**
     * Elimina prodotto con tutte le relative versioni.
     * 
     */
    deleteChangeLog = () => {
        this.setState({ isLoading: true });
        try {

            let that = this;
            axiosInstance.delete(`${API_DELETE_CHANGELOG}?productName=${this.state.typeChangeLog}`)
                .then(function () {
                    that.getChangeLog();
                    that.props.successNotify('Prodotto rimosso con successo!');
                    that.setState({ isLoading: false });
                });

        } catch (result) {
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Errore ' + result.response.status + ' su /api/Changelog/DeleteChangelog');
            }
        }
    }

    render() {
        const { role } = this.props;

        return (
            <Fragment>
                <div className="row" style={{ 'margin': '2rem 0 0 0' }}>
                    <div className="col-12">
                        <h2 style={{ 'marginLeft': '2rem' }}>News / Change Log</h2>
                    </div>
                </div>

                {
                    (this.state.loadingForRole || this.state.isLoading) ? <Loading /> :
                        <Fragment>
                            {
                                controlRole(role.changeLog, "api/Changelog", "PUT") &&
                                <div className="row justify-content-center titleButton">
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 text-center">
                                        <button className="btn btn-success removeMargin" onClick={this.hasClickNewChangeLog}>
                                            <i className="fas fa-plus"></i> Crea Nuovo Prodotto
                                        </button>
                                    </div>
                                    {
                                        // se risulta già un avviso di manutenzione sul sito, non generarne un altro (e sovrascriverlo)
                                        !this.state.inMaintenance &&
                                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 text-center">
                                            <button className="btn btn-warning" onClick={() => { this.newChangeLog(MAINTENANCE) }}>
                                                <i className="fas fa-exclamation-triangle"></i> Warning sul sito
                                            </button>
                                        </div>
                                    }
                                </div>
                            }

                            <div className="row justify-content-center buttonsGroup">
                                {
                                    !isEmpty(this.state.getChangeLog) &&
                                    Object.keys(this.state.getChangeLog).map((el, key) => {
                                        return (
                                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 text-center" style={{ margin: '2vh 0' }} key={key}>
                                                <button
                                                    name="typeChangeLog"
                                                    type="button"
                                                    value={el}
                                                    className={
                                                        (this.state.typeChangeLog === el && el !== MAINTENANCE) ? "btn btn-primary" :
                                                            ((this.state.typeChangeLog === el && el === MAINTENANCE) || el === MAINTENANCE) ? "btn btn-warning" :
                                                                "btn btn-outline-primary"
                                                    }
                                                    onClick={this.onChange}
                                                >
                                                    {el === MAINTENANCE ? 'WARNIG SITO' : el}
                                                </button>
                                                {
                                                    controlRole(role.changeLog, "api/Changelog/DeleteChangelog", "DELETE") &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => { this.hasClickDeleteChangeLog(el) }}
                                                    >
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                !isEmpty(this.state.getChangeLog) &&
                                <div className="changeLog">
                                    {
                                        (controlRole(role.changeLog, "api/Changelog", "PUT") && this.state.typeChangeLog !== MAINTENANCE) &&
                                        <div className="row justify-content-center titleButton">
                                            <div className="col-12 text-center">
                                                <button className="btn btn-success removeMargin" onClick={this.hasClickNewVersionChangeLog}>
                                                    <i className="fas fa-plus"></i> Aggiungi Versione ai log
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !isEmpty(this.state.getChangeLog) &&
                                        Object.keys(this.state.getChangeLog).map(el => {
                                            return (
                                                Object.keys(this.state.getChangeLog[el]).map((elem, key) => {
                                                    return (
                                                        <Fragment key={key}>
                                                            {
                                                                this.state.typeChangeLog === el &&
                                                                <div className="row justify-content-center" >
                                                                    {
                                                                        this.state.typeChangeLog !== MAINTENANCE ?
                                                                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 text-right">
                                                                                {this.state.getChangeLog[el][elem].version} <br />
                                                                                <i style={{ 'fontSize': '13px' }}>
                                                                                    ({moment(this.state.getChangeLog[el][elem].createdAt).format('DD-MM-YYYY')})
                                                                                </i>
                                                                            </div>
                                                                            :
                                                                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 text-right">
                                                                                {this.state.getChangeLog[el][elem].version === '0.0.1' && <div style={{ background: '#d32f2f', textAlign: 'center', color: 'white' }}>ALERT ROSSO</div>}
                                                                                {this.state.getChangeLog[el][elem].version === '0.0.2' && <div style={{ background: '#4caf50', textAlign: 'center', color: 'white' }}>ALERT VERDE</div>}
                                                                                {this.state.getChangeLog[el][elem].version === '0.0.3' && <div style={{ background: '#ffd939', textAlign: 'center', color: 'black' }}>ALERT GIALLO</div>}
                                                                                {this.state.getChangeLog[el][elem].version === '0.0.4' && <div style={{ background: '#1b76d3', textAlign: 'center', color: 'white' }}>ALERT BLUE</div>}
                                                                            </div>
                                                                    }
                                                                    <div className="col">
                                                                        <ul>
                                                                            {
                                                                                Object.keys(this.state.getChangeLog[el][elem].languageLogs).map((log, keyLog) => {
                                                                                    return (
                                                                                        <li key={keyLog}>
                                                                                            {log}
                                                                                            <ul>
                                                                                                {
                                                                                                    this.state.getChangeLog[el][elem].languageLogs[log].split("\n").map((logLang, keyLogLang) => {
                                                                                                        return (
                                                                                                            <li key={keyLogLang}>
                                                                                                                {logLang}
                                                                                                            </li>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </ul>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 btnEdit">
                                                                        {
                                                                            controlRole(role.changeLog, "api/Changelog", "PUT") &&
                                                                            <button
                                                                                onClick={
                                                                                    () => {
                                                                                        this.hasClickEditChangeLog(this.state.getChangeLog[el][elem].version, this.state.getChangeLog[el][elem].languageLogs, this.state.getChangeLog[el][elem].createdAt)
                                                                                    }
                                                                                }
                                                                            >
                                                                                <i className="far fa-edit"></i> Modifica
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 btnDelete">
                                                                        {
                                                                            (
                                                                                controlRole(role.changeLog, "api/Changelog", "DELETE") &&
                                                                                this.state.typeChangeLog !== MAINTENANCE
                                                                            ) &&
                                                                            <button
                                                                                onClick={
                                                                                    () => {
                                                                                        this.hasClickDeleteVersionChangeLog(this.state.getChangeLog[el][elem].version)
                                                                                    }
                                                                                }
                                                                            >
                                                                                <i className="far fa-trash-alt"></i> Elimina
                                                                            </button>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            }
                                                        </Fragment>
                                                    )
                                                })
                                            )
                                        })
                                    }
                                </div>
                            }

                            {
                                (this.state.hasClickNewChangeLog || this.state.hasClickEditChangeLog || this.state.hasClickNewVersionChangeLog) &&
                                <ModalChangeLog
                                    titleModal={this.state.titleModal}
                                    type={
                                        this.state.hasClickNewChangeLog ? 'newChangeLog' :
                                            this.state.hasClickNewVersionChangeLog ? 'newVersionLog' :
                                                this.state.hasClickEditChangeLog ? 'editVersionLog' : ''
                                    }
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modalChangeLog}

                                    version={this.state.versionTemp}
                                    dateVersion={this.state.dateVersion}
                                    logsIt={this.state.logsIt}
                                    logsEn={this.state.logsEn}
                                    logsEs={this.state.logsEs}
                                    logsDe={this.state.logsDe}
                                    logsFr={this.state.logsFr}
                                    typeChangeLog={this.state.typeChangeLog}

                                    newChangeLog={this.newChangeLog}
                                    editVersionLog={this.editVersionLog}
                                    editWarning={this.editWarning}

                                    key={this.state.shortId}
                                    errorNotify={this.props.errorNotify}
                                />
                            }

                            {
                                (this.state.hasClickDeleteChangeLog || this.state.hasClickDeleteVersionChangeLog) &&
                                <Modal
                                    click={
                                        () => {
                                            if (this.state.hasClickDeleteVersionChangeLog) this.deleteVersionChangeLog();
                                            if (this.state.hasClickDeleteChangeLog) this.deleteChangeLog();
                                            this.toggleModal();
                                        }
                                    }
                                    titleModal={this.state.titleModal}
                                    contentModal={this.state.contentModal}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default withNavigate(ChangeLog);