import { workerActionType, notifyActions } from './';
import {
    API_WORKER_ACTIVE_JOBS,
} from '../../config';
import axiosInstance from '../../axiosInstance';

export const workerActions = {
    addActiveJob,
    removeActiveJob
};

// /**
//  * Setta nel payload il numero di pagamenti cercati con quel filtro
//  * 
//  * @param {String} totalPromo 
//  */
// function setNumberPromo(totalPromo) {
//   return {
//     type: promoActionType.SET_NUMBER_PROMO,
//     totalPromo
//   }
// }

/**
 * Crea una Active JOB
 * 
 * @param {Object} data dati del job da mandare al BE
 */
function addActiveJob(data) {
    return (dispatch) => {
        dispatch({
            type: workerActionType.ADD_ACTIVE_JOB,
            async payload() {
                try {
                    await axiosInstance.post(`${API_WORKER_ACTIVE_JOBS}`, data);

                } catch (result) {
                    if (result && result.response && result.response.status) {
                        switch (result.response.status) {
                            default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_WORKER_ACTIVE_JOBS} - POST`)); break;
                        }
                    }
                }
            }
        })
    }
}

/**
 * Rimuove un Active JOB.
 * 
 * @param {String} id del job da eliminare
 */
function removeActiveJob(id) {
    return (dispatch) => {
        dispatch({
            type: workerActionType.REMOVE_ACTIVE_JOB,
            async payload() {
                try {
                    await axiosInstance.delete(`${API_WORKER_ACTIVE_JOBS}`, id);
                    dispatch(notifyActions.successAction('Active Jobs cancellato ' + id));

                } catch (result) {
                    if (result && result.response && result.response.status) {
                        switch (result.response.status) {
                            default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_WORKER_ACTIVE_JOBS} - DELETE`)); break;
                        }
                    }
                }
            }
        })
    }
}