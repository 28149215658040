import React, { Component, Fragment } from 'react';

import { API_WORKER_STATUS, API_WORKER_START, API_WORKER_STOP } from '../../config';
import './style.scss';
import Date from '../../common/date';
import Loading from '../../common/loading';
import { controlRole } from '../../common/actions/controlRole';
import axiosInstance from '../../axiosInstance';

export default class worker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            channelConnectedStartDate: null,
            isChannelConnected: false,
            isServiceStartedConnected: false,
            isServiceStartedStartDate: null,
        }
    }

    componentDidMount() {
        this.getWorkerStatus();
    }

    /**
     * Chiama il BE e restituisce tutte le informazioni sul worker.
     * Se la chiamata va a buon fine, chiama tutte le altre liste (active jobs, service, task, ecc...).
     * 
     * Questa funzione viene chiamata sia al caricamento iniziale che al refresh del worker.
     */
    getWorkerStatus = async () => {
        const { errorNotify } = this.props;
        this.setState({ isLoading: true });

        try {
            let response = await axiosInstance.get(API_WORKER_STATUS);
            this.setState({
                isLoading: false,
                channelConnectedStartDate: response.data.channelConnectedStartDate,
                isChannelConnected: response.data.isChannelConnected,
                isServiceStartedConnected: response.data.isServiceStartedConnected,
                isServiceStartedStartDate: response.data.isServiceStartedStartDate
            });
        } catch (result) {
            this.setState({ isLoading: false });
            if (result && result.response && result.response.status) {
                errorNotify('Errore ' + result.response.status + ' nel caricamento del Worker Status');
            }
        }
    }

    /**
     * Fa partire il worker.
     */
    startWorker = () => {
        let that = this;
        let thatProps = this.props;
        this.setState({ isLoading: true });

        axiosInstance.post(API_WORKER_START)
            .then(function () {
                thatProps.successNotify('Worker Avviato!');
                that.getWorkerStatus();
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                thatProps.errorNotify(`Error ${result.response.status} su ${API_WORKER_START} - POST`);
            });
    }

    /**
     * Ferma il worker.
     */
    stopWorker = () => {
        let that = this;
        let thatProps = this.props;
        this.setState({ isLoading: true });

        axiosInstance.post(API_WORKER_STOP)
            .then(function () {
                thatProps.successNotify('Worker Fermato!');
                that.getWorkerStatus();
            })
            .catch(function (result) {
                that.setState({ isLoading: false });
                thatProps.errorNotify(`Error ${result.response.status} su ${API_WORKER_STOP} - POST`);
            });
    }

    /**
     * Restituisce l'icona colorata in base allo status.
     * Definisco se la status sia connesso o meno tramite icona verde o rossa.
     * 
     * @param {Boolean} status 
     */
    status = (status, text) => {
        if (status) {
            return <span className="badge badge-success">{text}</span>;
        } else {
            return <span className="badge badge-danger" style={{ 'paddingTop': '15px' }}>{text}</span>;
        }
    }

    render() {
        const { worker, role } = this.props;
        return (
            <div className="row workerStatus">
                {(worker.isLoading || this.state.isLoading) && <Loading />}

                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 title">
                    <h5> Status Worker </h5>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-center">
                    {this.status(this.state.isChannelConnected, this.state.isChannelConnected ? "Channel Connected:" : "Channel Not Connected")}
                    {
                        this.state.isChannelConnected &&
                        <Fragment>
                            <br />
                            <Date date={this.state.channelConnectedStartDate} onlyLabel />
                        </Fragment>
                    }
                </div>

                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-center">
                    {this.status(this.state.isServiceStartedConnected, this.state.isServiceStartedConnected ? "Service Started Connected:" : "Service Started Not Connected")}
                    {
                        this.state.isServiceStartedConnected &&
                        <Fragment>
                            <br />
                            <Date date={this.state.isServiceStartedStartDate} onlyLabel />
                        </Fragment>
                    }
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-center">
                    {
                        controlRole(role.worker, "api/Worker/Start", "POST") &&
                        <button className="btn btn-info" onClick={this.startWorker}>
                            START
                        </button>
                    }
                    {
                        controlRole(role.worker, "api/Worker/Stop", "POST") &&
                        <button className="btn btn-danger" onClick={this.stopWorker}>
                            STOP
                        </button>
                    }
                    <button className="btn btn-warning" onClick={this.getWorkerStatus}>
                        REFRESH
                    </button>
                </div>
            </div>
        )
    }
}