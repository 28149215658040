import axios from 'axios';
import { API_URL } from './config';
import { userActionType } from './store/actions';

const axiosInstance = axios.create({
    baseURL: API_URL, // Replace with your API base URL
    headers: { 'Content-Type': 'application/json' }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before the request is sent
        const token = localStorage.getItem('token'); // Retrieve auth token from localStorage

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },

    function (error) {
        // Handle the error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        // Do something with the response data
        return response;
    },

    function (error) {
        // Handle the response error
        if ((error.response && (error.response.status === 401 || error.response.status === 403))) {
            // Handle unauthorized error
            console.error('Unauthorized, logging out...');
            // Perform any logout actions or redirect to login page

            logoutRequest();
        }

        return Promise.reject(error);
    }
);


function logoutRequest() {
    localStorage.removeItem('token');

    return {
        type: userActionType.LOGOUT_REQUEST,
        payload: {
            user: {}
        }
    }
}

export default axiosInstance;