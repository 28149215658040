import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import Date from '../../common/date';

import './style.scss';
import FormFilter from '../components/formFiltersBox';
import Loading from '../../common/loading';
import { API_VPN_BOX, API_VPN_BOX_REBOOT, API_VPN_BOX_COUNTRIES } from '../../config';
import ExpanableComponent from '../components/expanableComponentBox';
import ActionMenu from '../components/actionMenuBox';
import CSVExport from '../../common/csvExport';
import { controlRole } from '../../common/actions/controlRole';
import { returnNationality } from '../../common/actions/returnNationality';
import SelectorCopy from '../../common/selectorCopy';
import Modal from '../../common/modal';
import ModalVpnBox from '../../common/modal/modalVpnBox';
import ModalDiagnostics from '../../common/modal/modalDiagnostics';
import MyTooltip from '../../common/tooltip';
import IconLicence from '../../common/iconLicence';
import { isEmpty, withNavigate } from '../../utility';
import axiosInstance from '../../axiosInstance';

class vpnBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            // contiene la lista di tutte le vpnBox
            getVpnBoxList: [],

            // numero di pagine vpnbox che ci sono nel DB
            totalPages: '',

            // numero di pagina selezionata
            numbersPage: [],

            // quante vpnbox vuoi visualizzare
            numberVpnBox: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // variabili di controllo per modali
            hasClickEditVpnBox: false,
            hasClickRemoveVpnBox: false,
            hasClickRebootVpnBox: false,
            hasClickDiagnosticsVpnBox: false,

            // variabile temporanea per trasportare i dati da editare di una vpnbox
            vpnBoxTempEdit: {},

            titleModal: '',
            contentModal: '',
            modalVpnBox: false,
            modal: false,
            modalDiagnosticsVpnBox: false,
            openFilters: true,
            openFiltersDesktop: false,

            // usato per i filtri della ricerca
            paramsForSearch: {
                deviceId: this.props.vpn.idVpnPassForDetail === '' ? '' : this.props.vpn.idVpnPassForDetail,
                description: '',
                deviceModel: '',
                deviceVersion: '',
                devicePlatform: '',
                createdStartAt: '',
                createdEndAt: '',
                typeOrder: '',
                fieldOrder: '',
                user: '',
                key: ''
            },

            loadingForRole: false
        }
    }


    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare le Vpn Box, viene rispedito alla home
        if (!isEmpty(this.props.role.vpnBox)) {
            if (!controlRole(this.props.role.vpnBox, "api/VpnBox", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le Vpn Box!')
            }
        }

        this.countriesVpnBox();
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare le Vpn Box, viene rispedito alla home
        if (!isEmpty(this.props.role.vpnBox)) {
            if (!controlRole(this.props.role.vpnBox, "api/VpnBox", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le Vpn Box!')
            }
        }

        if (!localStorage.token) {
            this.props.handleNavigation(this.props.navigate, '/');
        }
    }

    getVpnBoxList = async (changeNumberPage) => {
        try {
            let params = {
                'elementsByPage': this.state.numberVpnBox,
                'page': changeNumberPage ? this.state.currentNumberPage : 1,
                'sortField': this.state.paramsForSearch.sortField,
                'sortType': this.state.paramsForSearch.sortType,
                'filters[0][DeviceId]': this.state.paramsForSearch.deviceId,
                'filters[1][Description]': this.state.paramsForSearch.description,
                'filters[2][CreatedAtStart]': this.state.paramsForSearch.createdStartAt === null ? '' : this.state.paramsForSearch.createdStartAt,
                'filters[3][CreatedAtEnd]': this.state.paramsForSearch.createdEndAt === null ? '' : this.state.paramsForSearch.createdEndAt,
                'filters[4][DeviceModel]': this.state.paramsForSearch.deviceModel,
                'filters[5][DeviceVersion]': this.state.paramsForSearch.deviceVersion,
                'filters[6][DevicePlatform]': this.state.paramsForSearch.devicePlatform,
                'filters[7][User]': this.state.paramsForSearch.user,
                'filters[8][Key]': this.state.paramsForSearch.key,
            }

            let response = await axiosInstance.get(`${API_VPN_BOX}`, { params: params });

            if (!changeNumberPage) {
                this.setState({
                    currentNumberPage: 1
                });
            }

            this.setState({
                getVpnBoxList: response.data.value.value,
                totalPages: response.data.info.totalPages,
                isLoading: false
            });


            this.props.setNumberVpnBox(response.data.info.total);
            this.setNumbersPage(response.data.info.totalPages);
            this.props.setKeyVpnPassForDetail('');
            this.props.setIdVpnPassForDetail('');

        } catch (result) {
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Errore ' + result.response.status + ' su chiamata Vpn Box');
            }
            this.setState({ isLoading: false });
        }
    }

    /**
     * Dato un ID device, vai alla schermata delle VPN Pass portando id vpnBox nei filtri
     */
    goToVpnPass = (idVpnPass) => {
        this.props.setKeyVpnPassForDetail(idVpnPass);
        this.props.handleNavigation(this.props.navigate, `/vpn/pass`);
    }

    /**
     * Se clicca disgnostica della Vpn Box, attiva la modale
     */
    hasClickDiagnosticsVpnBox = (data) => {
        this.setState({
            hasClickDiagnosticsVpnBox: true,
            titleModal: "Diagnostica Vpx Box - " + data.id,
            vpnBox: data
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalDiagnosticsVpnBox: !prevState.modalVpnBox }));
        }, 200);
    }

    /**
     * Se clicca modifica Vpn Box, attiva la modale
     */
    hasClickEditVpnBox = (data) => {
        this.setState({
            hasClickEditVpnBox: true,
            titleModal: "Modifica Vpx Box",
            vpnBox: data
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalVpnBox: !prevState.modalVpnBox }));
        }, 200);
    }

    /**
     * Se clicca rimuovi Vpn Box, attiva la modale
     */
    hasClickRemoveVpnBox = (data) => {
        this.setState({
            hasClickRemoveVpnBox: true,
            titleModal: "Rimuovi Vpn Box",
            contentModal: 'Sei sicuro di voler rimuovere la Vpn Box: ' + data.id + ' ?',
            id: data.id,
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Se clicca reboot Vpn Box, attiva la modale
     */
    hasClickRebootVpnBox = (data) => {
        this.setState({
            hasClickRebootVpnBox: true,
            titleModal: "Riavvia Vpn Box",
            contentModal: 'Sei sicuro di voler riavviare la Vpn Box: ' + data.id + ' ?',
            id: data.id,
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * In base al click dell'azione dal menu della vpnBox, effettua una determinata funzione.
     * @param {*} data 
     */
    actionClick = (action, data) => {
        switch (action) {
            case 'diagnostics': this.hasClickDiagnosticsVpnBox(data); break;
            case 'edit': this.hasClickEditVpnBox(data); break;
            case 'remove': this.hasClickRemoveVpnBox(data); break;
            case 'reboot': this.hasClickRebootVpnBox(data); break;
            default: break;
        }
    }

    /**
     * Chiama il BE, e fa il reboot della Vpn Box.
     * Dopodiche chiude la modale e aggiorna la lista delle Vpn Box.
     * 
     */
    rebootVpnBox = (serial) => {
        let that = this;
        let thatProps = this.props;

        axiosInstance.get(`${API_VPN_BOX_REBOOT}/${serial}`)
            .then(function () {
                thatProps.successNotify('Vpn Box riavviata con successo!');
                // that.toggleModal();
                // that.getVpnBoxList();
                that.setState({
                    hasClickRebootVpnBox: false,
                    modal: false
                });
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('Vpn Box non trovata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_BOX_REBOOT} - GET`); break;
                }
            });
    }

    /**
     * Chiama il BE, e fornisce i paesi dove è possibile usare la Vpn Box.
     */
    countriesVpnBox = async () => {
        let thatProps = this.props;

        let response = await axiosInstance.get(`${API_VPN_BOX_COUNTRIES}`)
            .catch(function (result) {
                switch (result.response.status) {
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_BOX_COUNTRIES} - GET`); break;
                }
            });

        this.setState({
            getCountriesList: response.data,
        });
    }

    /**
     * Chiama il BE, invia i parametri per la modifica di una Vpn Box.
     * Dopodiche chiude la modale e aggiorna la lista delle Vpn Box.
     * 
     * @param data sono i dati della Vpn Box da modificare
     */
    editVpnBox = (serial, data) => {
        let that = this;
        let thatProps = this.props;

        axiosInstance.put(`${API_VPN_BOX}/${serial}`, data)
            .then(function () {
                thatProps.successNotify('Vpn Box modificata con successo!');
                that.toggleModal();
                that.getVpnBoxList();
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('Vpn Box non trovata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_BOX} - PUT`); break;
                }
            });
    }

    /**
     * Chiama il BE, invia l'id per la rimozione di una Vpn Box.
     * Dopodiche chiude la modale e aggiorna la lista delle Vpn Box rimuovendo quella in precedenza dalla lista.
     * 
     * @param id della Vpn Box da rimuovere
     */
    removeVpnBox = (id) => {
        let that = this;
        let thatProps = this.props;

        axiosInstance.delete(`${API_VPN_BOX}/${id}`)
            .then(function () {
                thatProps.successNotify('Rimossa Vpn Box con successo!');
                that.toggleModal();
                that.getVpnBoxList();
                that.setState({ isLoading: false });
            })
            .catch(function (result) {
                switch (result.response.status) {
                    case 404: thatProps.errorNotify('Vpn Box non trovata!'); break;
                    default: thatProps.errorNotify(`Error ${result.response.status} su ${API_VPN_BOX} - DELETE`); break;
                }
            });
    }

    /**
     * In base alla risposta del server, 
     * setta il numero delle pagine disponibili per la paginazione
     */
    setNumbersPage = (numbersPage) => {
        let temp = [];
        for (let i = 1; i <= parseInt(numbersPage); i++) {
            temp.push(i);
        }
        this.setState({ numbersPage: temp });
    }

    /**
     * Setta il numero di pagina selezionato dall'utente
     * o il numero di elementi da visualizzare, richiamando il BE.
     * 
     * @param {*} event 
     * @param {*} newItem 
     */
    setChangePagination = (event, newItem) => {
        if (event.target.name === 'numberVpnBox') {
            this.setState({
                isLoading: true,
                numberVpnBox: newItem
            });
        }
        else if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                isLoading: true,
                currentNumberPage: newItem,
            });
        }

        setTimeout(() => {
            this.getVpnBoxList(true);
        }, 100);
    }

    /**
    * Setta l'oggetto che contiene i filtri per la ricerca
    * 
    * @param {*} data contengono i filtri per la ricerca
    */
    setParamsForSearch = (data) => {
        this.setState({
            isLoading: true,
            paramsForSearch: data
        });

        setTimeout(() => {
            this.getVpnBoxList();
        }, 100);
    }

    /**
     * Quando utilizzo una modale, viene attivata la funzione per quella determinata modale.
     * 
     * @param data
     */
    actionModal = (serial, data) => {
        this.setState({ isLoading: true });
        if (this.state.hasClickEditVpnBox) {
            this.editVpnBox(serial, data);
        }
        if (this.state.hasClickRemoveVpnBox) {
            this.removeVpnBox(this.state.id);
        }
        if (this.state.hasClickRebootVpnBox) {
            this.rebootVpnBox(this.state.id);
        }
    }

    /**
     * Chiude le modali per la gestione delle VpnBox, resettando i valori
     */
    toggleModal = () => {
        this.setState({
            hasClickEditVpnBox: false,
            hasClickRemoveVpnBox: false,
            hasClickRebootVpnBox: false,
            hasClickDiagnosticsVpnBox: false,
            titleModal: '',
            contentModal: '',
            modal: false,
            modalVpnBox: false,
            modalDiagnosticsVpnBox: false
        });
    }

    render() {
        const { role, user, vpn } = this.props;
        const columns = [
            {
                name: 'isOnline',
                selector: row => <IconLicence typeIcon={row.isOnline ? 'online' : 'offline'} forOnline />,
                width: '80px',
            },
            {
                name: 'Serial',
                selector: row =>
                    row.id !== null ?
                        <Fragment>
                            <span className="clickGoTo" onClick={() => { this.goToVpnPass(row.id) }} style={{ marginRight: '10px' }}>
                                <MyTooltip
                                    title="Vai alla VPN Pass Associata"
                                    position="top"
                                    content={<i className="fas fa-share-square"></i>}
                                />
                            </span>
                            <SelectorCopy item={row.id} infoNotify={this.props.infoNotify} />
                        </Fragment>
                        :
                        "--"
            },
            {
                name: 'Description',
                selector: row => isEmpty(row.description) ? '-' : row.description
            },
            {
                name: 'Country',
                selector: row => isEmpty(row.country) ? '-' : (row.country === '0' || row.country === null) ? 'Auto' : returnNationality(row.country)
            },
            {
                name: 'CreatedAt',
                selector: row => <Date format="L" date={row.createdAt} />
            },
            {
                selector: row => <ActionMenu actionClick={this.actionClick} vpnBox={row} role={role} userRole={user} data={this.state.getVpnBoxList} />,
                minWidth: '80px',
                maxWidth: '80px',
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {
                    this.state.loadingForRole ? <Loading /> :
                        <Fragment>
                            <div className="row">
                                <div className="col-12 vpnDesktop">

                                    {(this.props.vpn.isLoading || this.state.isLoading) && <Loading />}

                                    <div className="contentFilterSearch">
                                        <FormFilter
                                            setLoading={this.setLoading}
                                            totalPages={this.state.totalPages}
                                            currentNumberPage={this.state.currentNumberPage}
                                            numbersPage={this.state.numbersPage}
                                            numberVpnBox={this.state.numberVpnBox}
                                            vpn={vpn}
                                            setParamsForSearch={this.setParamsForSearch}
                                            setChange={this.setChangePagination}

                                            warningNotify={this.props.warningNotify}
                                            errorNotify={this.props.errorNotify}
                                            infoNotify={this.props.infoNotify}
                                            successNotify={this.props.successNotify}
                                        />
                                    </div>

                                    <div className="contentUser">
                                        <div className="row titleMobile">
                                            <div className="col-6">
                                                <h2 className='inlineBlock'>VPN Box</h2>
                                                {
                                                    vpn.totalVpnBox &&
                                                    <div className="mybadge">
                                                        {vpn.totalVpnBox}
                                                    </div>
                                                }
                                            </div>

                                            <div className="col-6 text-end">
                                                <CSVExport
                                                    data={this.state.getVpnBoxList}
                                                    filename="list-box.csv"
                                                    label="Scarica la lista delle Box selezionate"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                                <DataTable
                                                    columns={columns}
                                                    data={this.state.getVpnBoxList}
                                                    customStyles={customStyles}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    expandableRows={this.state.isLoading ? false : true}
                                                    expandOnRowClicked
                                                    expandableRowsComponent={ExpanableComponent}
                                                    expandableRowsComponentProps={{
                                                        "infoNotify": this.props.infoNotify,
                                                        "setKeyVpnPassForDetail": this.props.setKeyVpnPassForDetail,
                                                        "handleNavigation": this.props.handleNavigation
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {
                                this.state.hasClickEditVpnBox &&
                                <ModalVpnBox
                                    click={this.actionModal}
                                    vpnBox={this.state.vpnBox}
                                    getCountriesList={this.state.getCountriesList}

                                    successNotify={this.props.successNotify}
                                    errorNotify={this.props.errorNotify}
                                    warningNotify={this.props.warningNotify}

                                    titleModal={this.state.titleModal}
                                    stateModal={this.state.modalVpnBox}
                                    toggle={this.toggleModal}
                                />
                            }

                            {
                                this.state.hasClickDiagnosticsVpnBox &&
                                <ModalDiagnostics
                                    titleModal={this.state.titleModal}
                                    contentModal={this.state.contentModal}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modalDiagnosticsVpnBox}

                                    vpnBox={this.state.vpnBox}
                                    click={this.actionModal}
                                    vpnLogs={this.props.getVpnBoxLogs}

                                    successNotify={this.props.successNotify}
                                    errorNotify={this.props.errorNotify}
                                    warningNotify={this.props.warningNotify}
                                    infoNotify={this.props.infoNotify}
                                />
                            }

                            {
                                (this.state.hasClickRemoveVpnBox || this.state.hasClickRebootVpnBox) &&
                                <Modal
                                    click={this.actionModal}
                                    titleModal={this.state.titleModal}
                                    contentModal={this.state.contentModal}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default withNavigate(vpnBox);