import { vpnActionType, notifyActions } from './';
import {
  API_VPN_PASS
} from '../../config';
import axiosInstance from '../../axiosInstance';

export const vpnActions = {
  setIdVpnPassForDetail,
  setKeyVpnPassForDetail,
  setNumberVpnMachine,
  setNumberVpnPeer,
  setNumberVpnPass,
  setNumberVpnBox,
  addVpnPass,
  editVpnPass,
  removeVpnPass
};

/**
 * Setta nel payload un id di VPN Pass che è stato selezionato per visualizzarlo nel dettaglio
 * 
 * @param {String} idVpnPassForDetail 
 */
function setIdVpnPassForDetail(idVpnPassForDetail) {
  return {
    type: vpnActionType.SET_ID_VPN_PASS_FOR_DETAIL,
    idVpnPassForDetail
  }
}

/**
 * Setta nel payload un id di VPN Pass che è stato selezionato per visualizzarlo nel dettaglio
 * 
 * @param {String} keyVpnPassForDetail 
 */
function setKeyVpnPassForDetail(keyVpnPassForDetail) {
  return {
    type: vpnActionType.SET_KEY_VPN_PASS_FOR_DETAIL,
    keyVpnPassForDetail
  }
}

/**
 * Viene settato nello store quante macchine vpn ci sono dopo la chiamata get al BE.
 * 
 * @param {*} totalVpnMachine 
 */
function setNumberVpnMachine(totalVpnMachine) {
  return {
    type: vpnActionType.SET_NUMBER_VPN_MACHINE,
    totalVpnMachine
  }
}

/**
 * Viene settato nello store quanti peer ci sono dopo la chiamata get al BE.
 * 
 * @param {*} totalVpnPeer 
 */
function setNumberVpnPeer(totalVpnPeer) {
  return {
    type: vpnActionType.SET_NUMBER_VPN_PEER,
    totalVpnPeer
  }
}

/**
 * Viene settato nello store quanti peer ci sono dopo la chiamata get al BE.
 * 
 * @param {*} totalVpnPass 
 */
function setNumberVpnPass(totalVpnPass) {
  return {
    type: vpnActionType.SET_NUMBER_VPN_PASS,
    totalVpnPass
  }
}

/**
 * Viene settato nello store quante vpnBox ci sono dopo la chiamata get al BE.
 * 
 * @param {*} totalVpnBox 
 */
function setNumberVpnBox(totalVpnBox) {
  return {
    type: vpnActionType.SET_NUMBER_VPN_BOX,
    totalVpnBox
  }
}

/**
 * Aggiunge una VPN Pass nuova.
 * 
 * @param {Object} data sono contenuti tutti i dati per creare la VPN pass
 */
function addVpnPass(data) {
  return (dispatch) => {
    dispatch({
      type: vpnActionType.ADD_VPN_PASS,
      async payload() {
        try {
          await axiosInstance.post(`${API_VPN_PASS}`, data);

          dispatch(notifyActions.successAction('VPN Pass aggiunta con successo!'));

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_VPN_PASS} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Modifica una VPN Pass.
 * 
 * @param {Object} data sono contenuti tutti i dati per modificare la VPN pass
 */
function editVpnPass(data) {
  return (dispatch) => {
    dispatch({
      type: vpnActionType.EDIT_VPN_PASS,
      async payload() {
        try {
          let correctData = {
            productName: data.productName,
            createdAt: data.createdAt,
            expireAt: data.expireAt,
            maxAllowedPeers: data.maxAllowedPeers,
            user: data.user,
            paymentId: data.paymentId,
            description: data.description,
            // bundledIn: data.bundledIn
          }
          await axiosInstance.put(`${API_VPN_PASS}/${data.id}`, correctData);

          dispatch(notifyActions.successAction('VPN Pass modificata con successo!'));

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('VPN non trovata!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_VPN_PASS} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Rimuove una VPN Pass.
 * 
 * @param {String} id della vpn pass da rimuovere
 */
function removeVpnPass(id) {
  return (dispatch) => {
    dispatch({
      type: vpnActionType.DELETE_VPN_PASS,
      async payload() {
        try {
          await axiosInstance.delete(`${API_VPN_PASS}/${id}`);

          dispatch(notifyActions.successAction('Rimossa VPN Pass con successo!'));

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              case 404: dispatch(notifyActions.errorAction('VPN Pass non trovata!')); break;
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_VPN_PASS} - DELETE`)); break;
            }
          }
        }
      }
    })
  }
}