import store from '../index';
import isEmpty from 'lodash/isEmpty';
import { notifyActions, stockActionType } from './';
import {
  API_VPN_BOX_STOCK_INFO,
  API_VPN_BOX_STOCK,
  API_START_RENDER_BOX_STOCK,
  API_END_RENDER_BOX_STOCK,
  API_REBOOK_BOX_STOCK
} from '../../config';
import axiosInstance from '../../axiosInstance';

export const stockActions = {
  getVpnBoxStockInfo,
  getVpnBoxStock,
  addVpnStockMovement,
  removeVpnStockMovement,
  associateTracking,
  startRender,
  rebook,
  endRender
};

/**
 * Ritorna il quantitativo di box presenti in magazzino (rimanenti, venduti e totali).
 * 
 * @param {Object} data
 */
function getVpnBoxStockInfo() {
  return (dispatch) => {
    dispatch({
      type: stockActionType.GET_VPNBOX_STOCK_INFO,
      async payload() {
        try {
          let response = await axiosInstance.get(API_VPN_BOX_STOCK_INFO);

          return {
            available: response.data.available,
            busy: response.data.busy,
            load: response.data.load,
            unload: response.data.unload,
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_VPN_BOX_STOCK_INFO} - GET`)); break;
            }
          }

          return {
            available: 0,
            busy: 0,
            load: 0,
            unload: 0,
          }
        }
      }
    })
  }
}

/**
 * Ritorna i movimenti delle box in stock con i relativi filtri.
 *
 * @param {Object} data
 */
function getVpnBoxStock(data) {
  return (dispatch) => {
    dispatch({
      type: stockActionType.VPN_BOX_STOCK,
      async payload() {
        try {
          let response = await axiosInstance.get(`${API_VPN_BOX_STOCK}`, { params: data });

          let valueTypeForTitleTemp;
          // eslint-disable-next-line
          Object.keys(data).map(item => {
            if (item === 'filters[4][Type]')
              valueTypeForTitleTemp = data[item];
          })

          return {
            listVpnBoxStockMovement: response.data.value.value,
            totalPages: response.data.info.totalPages,
            paramsVpnBoxStockMovementTemp: data,
            valueTypeForTitle: valueTypeForTitleTemp
          }

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_VPN_BOX_STOCK} - GET`)); break;
            }
          }

          return {
            listVpnBoxStockMovement: [],
            totalPages: '',
            paramsVpnBoxStockMovementTemp: {}
          }
        }
      }
    })
  }
}

/**
 * Aggiungi uno stock di vpn nuovo.
 */
function addVpnStockMovement(data) {
  return (dispatch) => {
    dispatch({
      type: stockActionType.ADD_MOVEMENT_BOXSTOCK,
      async payload() {
        try {
          await axiosInstance.post(API_VPN_BOX_STOCK, data)
            .then(() => {
              dispatch(notifyActions.successAction('Nuovo Stock Creato! 😉'));
              dispatch(getVpnBoxStock(store.getState().stock.paramsVpnBoxStockMovementTemp));
              dispatch(getVpnBoxStockInfo());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_VPN_BOX_STOCK} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Rimuovi uno stock vpn box.
 * 
 * @param id dello stock da rimuovere
 */
function removeVpnStockMovement(id) {
  return (dispatch) => {
    dispatch({
      type: stockActionType.REMOVE_MOVEMENT_BOXSTOCK,
      async payload() {
        try {
          await axiosInstance.delete(`${API_VPN_BOX_STOCK}/${id}`)
            .then(() => {
              dispatch(notifyActions.successAction('Stock Rimosso! 😉'));
              dispatch(getVpnBoxStock(store.getState().stock.paramsVpnBoxStockMovementTemp));
              dispatch(getVpnBoxStockInfo());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_VPN_BOX_STOCK} - DELETE`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Associa uno stock vpn box al numero di spedizione.
 */
function associateTracking(movId, data) {
  return (dispatch) => {
    dispatch({
      type: stockActionType.ASSOCIED_TRACKING,
      async payload() {
        try {
          await axiosInstance.put(`${API_VPN_BOX_STOCK}/${movId}`, data)
            .then(() => {
              if (!isEmpty(data.trackingNumber)) {
                dispatch(notifyActions.successAction('Numero di spedizione associato all\'ordine! 😉'));

              } else {
                dispatch(notifyActions.successAction('Ordine modificato con successo! 😉'));
              }

              dispatch(getVpnBoxStock(store.getState().stock.paramsVpnBoxStockMovementTemp));
              dispatch(getVpnBoxStockInfo());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_VPN_BOX_STOCK} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Avvia un reso o un'assistenza tecnica di stock vpn box (totale o parziale)
 * 
 * @param {*} movId 
 * @param {*} data 
 * @returns 
 */
function startRender(movId, data) {
  return (dispatch) => {
    dispatch({
      type: stockActionType.START_RENDER,
      async payload() {
        try {
          await axiosInstance.post(`${API_START_RENDER_BOX_STOCK}/${movId}`, data)
            .then(() => {
              if (data.operationType === 'R') {
                dispatch(notifyActions.successAction('Reso per rimborso avviato con successo! 😉'));
              } else {
                dispatch(notifyActions.successAction('Reso per assistenza tecnica avviato con successo! 😉'));
              }

              dispatch(getVpnBoxStock(store.getState().stock.paramsVpnBoxStockMovementTemp));
              dispatch(getVpnBoxStockInfo());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_START_RENDER_BOX_STOCK} - POST`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * 
 * 
 * @param {*} movId 
 * @returns 
 */
function rebook(movId) {
  return (dispatch) => {
    dispatch({
      type: stockActionType.REBOOK,
      async payload() {
        try {
          await axiosInstance.put(`${API_REBOOK_BOX_STOCK}/${movId}`)
            .then(() => {
              dispatch(notifyActions.successAction('Pratica chiusa con successo! 😉'));
              dispatch(getVpnBoxStock(store.getState().stock.paramsVpnBoxStockMovementTemp));
              dispatch(getVpnBoxStockInfo());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_REBOOK_BOX_STOCK} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}

/**
 * Termina la procedura di reso o assistenza tecnica, una volta che lo stock di box è arrivato a destinazione
 * 
 * @param {*} movId 
 * @returns 
 */
function endRender(movId) {
  return (dispatch) => {
    dispatch({
      type: stockActionType.END_RENDER,
      async payload() {
        try {
          await axiosInstance.put(`${API_END_RENDER_BOX_STOCK}/${movId}`)
            .then(() => {
              dispatch(notifyActions.successAction('Pratica chiusa con successo! 😉'));
              dispatch(getVpnBoxStock(store.getState().stock.paramsVpnBoxStockMovementTemp));
              dispatch(getVpnBoxStockInfo());
            });

        } catch (result) {
          if (result && result.response && result.response.status) {
            switch (result.response.status) {
              default: dispatch(notifyActions.errorAction(`Error ${result.response.status} su ${API_REBOOK_BOX_STOCK} - PUT`)); break;
            }
          }
        }
      }
    })
  }
}