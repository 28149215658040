import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';

import './style.scss';
import FormFilter from '../components/formFiltersPass';
import ModalVpnPass from '../../common/modal/modalVpnPass';
import Modal from '../../common/modal';
import { API_VPN_PASS } from '../../config';
import Loading from '../../common/loading';
import SelectorCopy from '../../common/selectorCopy';
import ActionMenu from '../components/actionMenuPass';
import ExpanableComponent from '../components/expanableComponentPass';
import MyTooltip from '../../common/tooltip';
import CSVExport from '../../common/csvExport';
import { controlRole } from '../../common/actions/controlRole';
import { isEmpty, withNavigate } from '../../utility';
import axiosInstance from '../../axiosInstance';

class vpnPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            // contiene la lista di tutte le vpn
            getVpnPassList: [],

            // numero di pagine vpn che ci sono nel DB
            totalPages: '',

            // numero di pagina selezionata
            numbersPage: [],

            // quante vpn vuoi visualizzare
            numberVpnPass: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // variabili di controllo per modali
            hasClickAddVpnPass: false,
            hasClickEditVpnPass: false,
            hasClickRemoveVpnPass: false,

            // variabile temporanea per trasportare i dati da editare di una vpn
            vpnTempEdit: {},

            titleModal: '',
            contentModal: '',
            modalVpn: false,
            modal: false,
            openFilters: true,
            openFiltersDesktop: false,

            // usato per i filtri della ricerca
            paramsForSearch: {
                sortField: '',
                sortType: '',
                id: this.props.vpn.idVpnPassForDetail === '' ? '' : this.props.vpn.idVpnPassForDetail,
                product: '',
                maxPeer: '',
                createdStartAt: null,
                createdEndAt: null,
                expireStartAt: null,
                expireEndAt: null,
                typeOrder: '',
                fieldOrder: '',
                user: '',
                key: this.props.vpn.keyVpnPassForDetail === '' ? '' : this.props.vpn.keyVpnPassForDetail
            },

            loadingForRole: false
        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare le VPN Pass, viene rispedito alla home
        if (!isEmpty(this.props.role.vpnPass)) {
            if (!controlRole(this.props.role.vpnPass, "api/VpnPass", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le VPN Pass!')
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare le VPN Pass, viene rispedito alla home
        if (!isEmpty(this.props.role.vpnPass)) {
            if (!controlRole(this.props.role.vpnPass, "api/VpnPass", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare le VPN Pass!')
            }
        }

        if (!localStorage.token) {
            this.props.handleNavigation(this.props.navigate, '/');
        }
    }

    /**
     * Chiude le modali per la gestione della VPN pass, resettando i valori
     */
    toggleModal = () => {
        this.setState({
            hasClickAddVpnPass: false,
            hasClickEditVpnPass: false,
            hasClickRemoveVpnPass: false,
            vpnTempEdit: {},
            titleModal: '',
            contentModal: '',
            idTempEdit: '',
            modal: false,
            modalVpn: false
        });
    }

    /**
     * Dato un ID utente, vai alla schermata degli utenti portando id utente nei filtri
     */
    goToUser = (idUser) => {
        this.props.handleNavigation(this.props.navigate, `/userInDetail/${idUser}`);
    }

    /**
     * Dato un id, vai alla schermata dei pagamenti portando id pagamento nei filtri
     */
    goToPayment = (paymentId) => {
        this.props.setIdPaymentForDetail(paymentId)
        this.props.handleNavigation(this.props.navigate, `/payments`);
    }

    /**
     * Se clicca aggiungi nuova VPN Account, attiva la modale
     */
    hasClickAddVpnPass = () => {
        this.setState({
            hasClickAddVpnPass: true,
            titleModal: "Aggiungi VPN Pass",
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalVpn: !prevState.modalVpn }));
        }, 200);
    }

    /**
     * Se clicca aggiungi nuova VPN Account, attiva la modale
     */
    hasClickEditVpnPass = (data) => {
        this.setState({
            hasClickEditVpnPass: true,
            titleModal: "Modifica VPN Pass",
            vpnTempEdit: data
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalVpn: !prevState.modalVpn }));
        }, 200);
    }

    /**
     * Se clicca rimuovi VPN Account, attiva la modale
     */
    hasClickRemoveVpnPass = (data) => {
        this.setState({
            hasClickRemoveVpnPass: true,
            titleModal: "Rimuovi VPN Account",
            contentModal: 'Sei sicuro di voler rimuovere la Pass: ' + data._id + ' ?',
            vpnTempEdit: data
        });
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    getVpnPassList = async (changeNumberPage) => {
        try {
            let params = {
                'elementsByPage': this.state.numberVpnPass,
                'page': changeNumberPage ? this.state.currentNumberPage : 1,
                'sortField': this.state.paramsForSearch.sortField,
                'sortType': this.state.paramsForSearch.sortType,
                'filters[0][Id]': this.state.paramsForSearch.id,
                'filters[1][Product]': this.state.paramsForSearch.product,
                'filters[2][CreatedAtStart]': this.state.paramsForSearch.createdStartAt === null ? '' : this.state.paramsForSearch.createdStartAt,
                'filters[3][CreatedAtEnd]': this.state.paramsForSearch.createdEndAt === null ? '' : this.state.paramsForSearch.createdEndAt,
                'filters[4][ExpireAtStart]': this.state.paramsForSearch.expireStartAt === null ? '' : this.state.paramsForSearch.expireStartAt,
                'filters[5][ExpireAtEnd]': this.state.paramsForSearch.expireEndAt === null ? '' : this.state.paramsForSearch.expireEndAt,
                'filters[6][MaxAllowedPeers]': this.state.paramsForSearch.maxPeer,
                'filters[7][User]': this.state.paramsForSearch.user,
                'filters[8][Key]': this.state.paramsForSearch.key,
            }

            let response = await axiosInstance.get(`${API_VPN_PASS}`, { params: params });

            if (!changeNumberPage) {
                this.setState({
                    currentNumberPage: 1
                });
            }

            this.setState({
                getVpnPassList: response.data.value.value,
                totalPages: response.data.info.totalPages,
                isLoading: false
            });


            this.props.setNumberVpnPass(response.data.info.total);
            this.setNumbersPage(response.data.info.totalPages);
            this.props.setKeyVpnPassForDetail('');
            this.props.setIdVpnPassForDetail('');

        } catch (result) {
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Errore ' + result.response.status + ' su chiamata VPN pass');
            }
            this.setState({ isLoading: false });
        }
    }

    /**
     * In base alla risposta del server, 
     * setta il numero delle pagine disponibili per la paginazione
     */
    setNumbersPage = (numbersPage) => {
        let temp = [];
        for (let i = 1; i <= parseInt(numbersPage); i++) {
            temp.push(i);
        }
        this.setState({ numbersPage: temp });
    }

    /**
     * Setta il numero di pagina selezionato dall'utente
     * o il numero di elementi da visualizzare, richiamando il BE.
     * 
     * @param {*} event 
     * @param {*} newItem 
     */
    setChangePagination = (event, newItem) => {
        if (event.target.name === 'numberVpnPass') {
            this.setState({
                isLoading: true,
                numberVpnPass: newItem
            });
        }
        else if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                isLoading: true,
                currentNumberPage: newItem,
            });
        }
        setTimeout(() => {
            this.getVpnPassList(true);
        }, 100);
    }

    /**
     * Quando utilizzo una modale, viene attivata la funzione per quella determinata modale.
     * 
     * @param data
     */
    actionModal = (data) => {
        this.setState({ isLoading: true });
        if (this.state.hasClickAddVpnPass) {
            this.props.addVpnPass(data);
            this.toggleModal();
            this.getVpnPassList();
            this.setState({ isLoading: false });
        }
        if (this.state.hasClickEditVpnPass) {
            this.props.editVpnPass(data);
            this.toggleModal();
            this.getVpnPassList();
            this.setState({ isLoading: false });
        }
        if (this.state.hasClickRemoveVpnPass) {
            this.props.removeVpnPass(this.state.vpnTempEdit._id);
            this.toggleModal();
            this.getVpnPassList();
            this.setState({ isLoading: false });
        }
    }

    /**
     * In base al click dell'azione dal menu della vpn, effettua una determinata funzione.
     * @param {*} data 
     */
    actionClick = (action, data) => {
        switch (action) {
            case 'edit': this.hasClickEditVpnPass(data); break;
            case 'remove': this.hasClickRemoveVpnPass(data); break;
            default: break;
        }
    }

    /**
     * Setta l'oggetto che contiene i filtri per la ricerca
     * 
     * @param {*} data contengono i filtri per la ricerca
     */
    setParamsForSearch = (data) => {
        this.setState({
            isLoading: true,
            paramsForSearch: data
        });

        setTimeout(() => {
            this.getVpnPassList();
        }, 100);
    }

    render() {
        const { role, vpn } = this.props;
        const columns = [
            {
                name: 'Key Pass',
                selector: row => <SelectorCopy item={row.key} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'ID Pagamento',
                selector: row =>
                    row.paymentId !== null ?
                        <Fragment>
                            <span className="clickGoTo" onClick={() => { this.goToPayment(row.paymentId) }} style={{ marginRight: '10px' }}>
                                <MyTooltip
                                    title="Vai a questo pagamento"
                                    position="top"
                                    content={<i className="fas fa-share-square"></i>}
                                />
                            </span>
                            <SelectorCopy item={row.paymentId} infoNotify={this.props.infoNotify} />
                        </Fragment>
                        :
                        "--"
            },
            {
                name: 'Utente',
                selector: row =>
                    row.user !== null ?
                        <Fragment>
                            <span className="clickGoTo" onClick={() => { this.goToUser(row.user) }} style={{ marginRight: '10px' }}>
                                <MyTooltip
                                    title="Vai a questo utente"
                                    position="top"
                                    content={<i className="fas fa-share-square"></i>}
                                />
                            </span>
                            <SelectorCopy item={row.user} infoNotify={this.props.infoNotify} />
                        </Fragment>
                        :
                        "--"
            },
            {
                name: 'Description',
                selector: row => (row.description === null || row.description === "") ? "-" : row.description
            },
            {
                selector: row => <ActionMenu actionClick={this.actionClick} vpnPass={row} role={role} data={this.state.getVpnPassList} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {
                    this.state.loadingForRole ? <Loading /> :
                        <Fragment>
                            <div className="row">
                                <div className="col-12 vpnDesktop">

                                    {(this.props.vpn.isLoading || this.state.isLoading) && <Loading />}

                                    <div className="contentFilterSearch">
                                        <FormFilter
                                            totalPages={this.state.totalPages}
                                            currentNumberPage={this.state.currentNumberPage}
                                            numbersPage={this.state.numbersPage}
                                            numberVpnPass={this.state.numberVpnPass}
                                            vpn={this.props.vpn}
                                            setChange={this.setChangePagination}
                                            setParamsForSearch={this.setParamsForSearch}

                                            warningNotify={this.props.warningNotify}
                                            errorNotify={this.props.errorNotify}
                                            infoNotify={this.props.infoNotify}
                                            successNotify={this.props.successNotify}
                                        />
                                    </div>

                                    <div className="contentUser">
                                        <div className="row titleMobile">
                                            <div className="col-6">
                                                <h2 className='inlineBlock'>VPN Pass</h2>
                                                {
                                                    vpn.totalVpnPass &&
                                                    <div className="mybadge">
                                                        {vpn.totalVpnPass}
                                                    </div>
                                                }
                                            </div>

                                            <div className="col-6 text-end">
                                                <CSVExport
                                                    data={this.state.getVpnPassList}
                                                    filename="list-pass.csv"
                                                    label="Scarica la lista delle Pass selezionate"
                                                />
                                            </div>

                                            <div className="col-12 text-center">
                                                {
                                                    controlRole(role.vpnPass, "api/VpnPass", "POST", true) &&
                                                    <button className="btn btn-success" onClick={this.hasClickAddVpnPass}>
                                                        <i className="fas fa-plus" style={{ 'marginRight': '5px' }}></i>
                                                        Aggiungi VPN Pass
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                                <DataTable
                                                    columns={columns}
                                                    data={this.state.getVpnPassList}
                                                    customStyles={customStyles}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    expandableRows={this.state.isLoading ? false : true}
                                                    expandOnRowClicked
                                                    expandableRowsComponent={ExpanableComponent}
                                                    expandableRowsComponentProps={{
                                                        "infoNotify": this.props.infoNotify,
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {
                                (this.state.hasClickAddVpnPass || this.state.hasClickEditVpnPass) &&
                                <ModalVpnPass
                                    click={this.actionModal}
                                    vpnTempEdit={this.state.vpnTempEdit}

                                    successNotify={this.props.successNotify}
                                    errorNotify={this.props.errorNotify}
                                    warningNotify={this.props.warningNotify}

                                    titleModal={this.state.titleModal}
                                    stateModal={this.state.modalVpn}
                                    toggle={this.toggleModal}
                                />
                            }

                            {
                                this.state.hasClickRemoveVpnPass &&
                                <Modal
                                    click={this.actionModal}
                                    titleModal={this.state.titleModal}
                                    contentModal={this.state.contentModal}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modal}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default withNavigate(vpnPass);