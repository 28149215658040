import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { isEmpty, isNil } from 'lodash';

import './style.scss';
import FormFilter from '../components/formFilters';
import Date from '../../common/date';
import Loading from '../../common/loading';
import ActionMenu from '../components/actionMenu';
import ExpanableComponent from '../components/expanableComponent';
import IconLicence from '../../common/iconLicence';
import ModalPlayer from '../../common/modal/modalPlayer';
import ModalSendMessagePlayer from '../../common/modal/modalSendMessagePlayer';
import SelectorCopy from '../../common/selectorCopy';
import CSVExport from '../../common/csvExport';
import { controlRole } from '../../common/actions/controlRole';
import { withNavigate } from '../../utility';

class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            hasClickEditPlayer: false,
            hasClickSendMessagePlayer: false,

            modal: false,
            modalSendMessagePlayer: false,
            openFilters: true,
            openFiltersDesktop: false,

            // dati del player da editare
            player: {
                licenceKey: '',
                description: '',
                playerId: '',
                user: '',
                vpnPass: ''
            },

            loadingForRole: true
        }

        this.setLoading = this.setLoading.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare i player, viene rispedito alla home
        if (!isEmpty(this.props.role.player)) {
            if (!controlRole(this.props.role.player, "api/Player/GetPlayers", "GET")) {
                this.props.handleNavigation(this.props.navigate, '/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare i player!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare i player, viene rispedito alla home
        if (!isEmpty(this.props.role.player)) {
            if (!controlRole(this.props.role.player, "api/Player/GetPlayers", "GET")) {
                this.props.handleNavigation(this.props.navigate, '/');
                this.props.infoNotify('Non hai nessun permesso per visualizzare i player!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                }
            }
        }

        if (!localStorage.token) {
            this.props.handleNavigation(this.props.navigate, '/');
        }
    }

    setLoading = (value) => {
        this.setState({ isLoading: value });
    }

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
        this.setState(prevState => ({ modalSendMessagePlayer: !prevState.modalSendMessagePlayer }));
        this.setState({
            hasClickEditPlayer: false,
            hasClickSendMessagePlayer: false
        });
    }

    /**
     * Al click del menu opzioni, controlla quale opzione sia stata selezionata 
     * e di conseguenza esegue l'azione per quella riga.
     * 
     * @param action è il tipo di azione selezionato
     * @param dataPlayer è l'oggetto contente le informazioni del player selezionato dell'utente per cui è richiesta quell'azione
     */
    actionClick = (action, dataPlayer) => {
        switch (action) {
            case 'editPlayer': this.hasClickEditPlayer(dataPlayer); break;
            case 'sendMessagePlayer': this.hasClickSendMessagePlayer(dataPlayer); break;
            case 'deletePlayer': this.removePlayer(dataPlayer._id); break;
            case 'deactiveLicence': this.deactiveLicence(action, dataPlayer); break;
            case 'refreshPlayer': this.props.refreshPlayer(dataPlayer, action); break;
            default: break;
        }
    }

    /**
     * Rimuove un player
     * 
     * @param id del player
     */
    removePlayer = (playerId) => {
        this.props.removePlayer(playerId);
    }

    /**
     * Azioni prima di aprire la modale di editazione player
     */
    hasClickEditPlayer = (data) => {
        this.setState({
            hasClickEditPlayer: true,
            hasClickSendMessagePlayer: false,
        });
        this.associaPlayer(data);
        setTimeout(() => {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }, 200);
    }

    /**
     * Azioni prima di aprire la modale per invio messaggio al player
     */
    hasClickSendMessagePlayer = (data) => {
        this.setState({
            hasClickEditPlayer: false,
            hasClickSendMessagePlayer: true,
        });
        this.associaPlayer(data);
        setTimeout(() => {
            this.setState(prevState => ({ modalSendMessagePlayer: !prevState.modalSendMessagePlayer }));
        }, 200);
    }

    /**
     * Modifica un player
     */
    sendEditPlayer = (data) => {
        this.props.sendEditPlayer(data);
    }

    /**
     * Manda un messaggio un player
     */
    sendMessagePlayer = (data) => {
        this.props.sendMessagePlayer(data);
        this.toggleModal();
    }

    /**
     * Disattiva la licenza di un player.
     * Viene passata una licenceKey non valida così il player verrà disattivato
     */
    deactiveLicence = (action, data) => {
        let newData = {
            licenceKey: '0000000000000000',
            description: data.description === "" ? "---" : data.description,
            id: data._id,
            user: data.user,
            vpnPass: data.vpnPass
        };

        this.props.sendEditPlayer(newData, action);
    }

    /**
     * Prende i dati passati dall'action del player (in particolar modo quando si seleziona edita player),
     * e li manda alla modale di editazione.
     * Se i dati sono presenti associa al player, altrimenti resetta
     */
    associaPlayer = (data) => {
        if (data) {
            this.setState({
                player: {
                    licenceKey: data.licenceKey,
                    description: data.description,
                    user: data.user,
                    playerId: data._id,
                    vpnPass: data.vpnPass
                },
            });
        }
    }

    render() {
        const { role, user, player, getPlayerList } = this.props;
        const columns = [
            {
                name: '',
                selector: row => <IconLicence typeIcon={row.licence !== null ? (row.licence.expireAt === null ? 'lifetime' : 'trial') : 'trial'} forDevice />,
                width: '60px'
            },
            {
                name: 'ID Device',
                selector: row => <SelectorCopy item={row._id} infoNotify={this.props.infoNotify} />,
                minWidth: '600px',
                maxWidth: '600px'
            },
            {
                name: 'ID Utente',
                selector: row => row.user === null ? 'Utente non registrato' : row.user,
                minWidth: '250px'
            },
            {
                name: 'Licenza',
                selector: row => row.licenceKey === null ? 'Licenza non valida' : row.licenceKey,
                minWidth: '200px',
                maxWidth: '200px'
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />,
                minWidth: '100px',
                maxWidth: '100px'
            },
            {
                selector: row => <ActionMenu actionClick={this.actionClick} player={row} role={role} data={player.listPlayer} />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true,
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {
                    this.state.loadingForRole ? <Loading /> :
                        <Fragment>
                            <div className="row">
                                <div className="col-12 playerDesktop">
                                    <div className="contentFilterSearch">
                                        <FormFilter
                                            setIdPlayerForDetail={this.props.setIdPlayerForDetail}
                                            createLicences={this.props.createLicences}

                                            player={player}
                                            role={this.props.role}
                                            user={user}
                                            getPlayerList={getPlayerList}

                                            warningNotify={this.props.warningNotify}
                                            infoNotify={this.props.infoNotify}
                                            errorNotify={this.props.errorNotify}
                                            successNotify={this.props.successNotify}

                                        />
                                    </div>

                                    <div className="contentUser">
                                        <div className="row titleMobile">
                                            <div className="col-6">
                                                <h2 className='inlineBlock'>Players</h2>
                                                {
                                                    player.totalPlayers &&
                                                    <div className="mybadge">
                                                        {player.isComplete ? player.totalPlayers : "più di " + player.totalPlayers + "..."}
                                                    </div>
                                                }
                                            </div>

                                            {
                                                !isNil(player.listPlayer) &&
                                                <div className="col-6 text-end">
                                                    <CSVExport
                                                        data={player.listPlayer}
                                                        filename="list-players.csv"
                                                        label="Scarica la lista degli utenti selezionata"
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <IconLicence legend forDevice />

                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                                <DataTable
                                                    columns={columns}
                                                    data={player.listPlayer}
                                                    customStyles={customStyles}
                                                    player={player}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    clearSelectedRows
                                                    expandableRows={this.state.isLoading ? false : true}
                                                    expandOnRowClicked
                                                    expandableRowsComponent={ExpanableComponent}
                                                    expandableRowsComponentProps={{
                                                        "handleNavigation": this.props.handleNavigation,
                                                        "getPlayerList": getPlayerList
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {(player.isLoading || this.state.isLoading || player.isLoadingChange) && <Loading />}
                                </div>
                            </div>
                            {
                                this.state.hasClickEditPlayer &&
                                <ModalPlayer
                                    titleModal='Modifica Player'
                                    stateModal={this.state.modal}
                                    clickEditPlayer={this.sendEditPlayer}
                                    toggle={this.toggleModal}

                                    licence={this.state.player.licenceKey}
                                    idPlayer={this.state.player.playerId}
                                    description={this.state.player.description}
                                    userId={this.state.player.user}
                                    vpnPass={this.state.player.vpnPass}
                                    errorNotify={this.props.errorNotify}
                                />
                            }
                            {
                                this.state.hasClickSendMessagePlayer &&
                                <ModalSendMessagePlayer
                                    titleModal="Invia un messaggio al player"
                                    stateModal={this.state.modalSendMessagePlayer}
                                    click={this.sendMessagePlayer}
                                    toggle={this.toggleModal}

                                    deviceId={this.state.player.playerId}
                                    errorNotify={this.props.errorNotify}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default withNavigate(Devices);