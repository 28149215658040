import React, { Component, Fragment } from 'react';
import './style.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { API_GET_PLAYER_LIST_URL } from '../../config';
import axiosInstance from '../../axiosInstance';

/**
 * Attivazione modale per aggiungere / modificare playlist al player
 * 
 *      "click" : se clicco OK, attivo una determinata funzione
 *      "titleModal" : il titolo della modale quando si apre
 *      "contentModal" : il contenuto che deve essere mostrato nella modale
 *      "userMail" : mail dell'utente a cui mandare la mail
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalManageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceId: this.props.deviceId !== '' ? this.props.deviceId : '',
            url: this.props.url !== '' ? this.props.url : '',
            title: this.props.title !== '' ? this.props.title : '',
            keyPlaylist: this.props.keyPlaylist !== '' ? this.props.keyPlaylist : '',

            deviceIdNull: false,
            titleNull: false,
            urlNull: false,

            // parametri per autocomplete
            listPlayers: [],
            activeSeeAutocomplete: false
        }
        this.setTitlePlaylist = this.setTitlePlaylist.bind(this);
        this.setUrlPlaylist = this.setUrlPlaylist.bind(this);
    }

    setUrlPlaylist = (event) => {
        this.setState({ url: event.target.value, urlNull: false });
    }

    setTitlePlaylist = (event) => {
        this.setState({ title: event.target.value, titleNull: false });
    }

    setDeviceIdPlaylist = (event) => {
        this.setState({ deviceId: event.target.value, deviceIdNull: false });
    }

    /**
     * Per attivare l'autocompletamento del device,
     * Carica tutti i device appena apri la modale
     */
    componentDidMount() {
        if (this.props.type === 'newPlaylist')
            this.getListDevice();
    }

    /**
     * Chiama il BE a ogni cambiamento di carattere dell'id device
     */
    setChangeId = (event) => {
        this.setState({ deviceId: event.target.value, deviceIdNull: false });

        if (event.target.value === '') {
            this.setState({ activeSeeAutocomplete: false });
        } else if (!this.state.activeSeeAutocomplete) {
            this.setState({ activeSeeAutocomplete: true });
        }

        setTimeout(() => {
            if (event.target.value.length > 5 && event.target.value.length < 12) {
                this.getListDevice();
            }
        }, 100);
    }

    /**
     * Setta il valore, al click dell'item autocomplete
     */
    setValueChangeId = (value) => {
        this.setState({
            newDeviceId: value,
            deviceId: value,
            activeSeeAutocomplete: false
        });
    }

    /**
     * Carica "solamente" 10 device per iniziare
     */
    getListDevice = async () => {
        try {
            let params = {
                'elementsByPage': '20',
                'filters[0][DeviceId]': this.state.deviceId,
            };

            let response = await axiosInstance.get(`${API_GET_PLAYER_LIST_URL}`, { params: params });

            let arrayTemp = [];

            Object.values(response.data.value.value).forEach((k) => {
                arrayTemp.push(k._id);
            })

            this.setState({ listPlayers: arrayTemp });


        } catch (result) {
            const { errorNotify } = this.props;
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    default: errorNotify(`Error ${result.response.status} su ${API_GET_PLAYER_LIST_URL} - GET`); break;
                }
            }
        }
    }

    confirm = () => {
        const { type, clickEdit, clickNew, clickRemove, errorNotify } = this.props;

        if (type === 'delete') {
            clickRemove(this.state.keyPlaylist);
        }
        else {
            if (this.state.title !== '') {
                if (this.state.url !== '') {
                    if (type === 'new') {
                        clickNew(this.state.title, this.state.url);

                    } else if (type === 'newPlaylist') {
                        clickNew(this.state.title, this.state.url, this.state.deviceId);

                    } else if (type === 'edit') {
                        clickEdit(this.state.title, this.state.url, this.state.keyPlaylist);
                    }
                } else {
                    errorNotify('Inserisci un url!');
                    this.setState({ titleNull: false, urlNull: true });
                }
            } else {
                errorNotify('Inserisci il titolo!');
                this.setState({ titleNull: true, urlNull: false });
            }
        }
    }

    render() {
        const { stateModal, toggle, titleModal, type } = this.props;
        return (
            <Dialog open={stateModal} disableScrollLock={true} className="modalPlayer">
                <DialogTitle>
                    {titleModal}
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className='fas fa-times'></i>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {
                        type === 'delete' ?
                            <p>
                                Sei sicuro di vuol rimuovere questa playlist?
                            </p>
                            :
                            <Fragment>
                                {
                                    type === 'newPlaylist' &&
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Device ID</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" value={this.state.deviceId} onChange={this.setChangeId} />
                                        {
                                            this.state.activeSeeAutocomplete &&
                                            <ul className="autoCompleteDevice">
                                                {
                                                    this.state.listPlayers.map((item, key) => {
                                                        return (
                                                            <li key={key} onClick={() => this.setValueChangeId(item)} >
                                                                {item}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        }
                                    </div>
                                }

                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput2" className="form-label">Title</label>
                                    <input type="text" className="form-control" id="exampleFormControlInput3" value={this.state.title} onChange={this.setTitlePlaylist} disabled={this.state.titleNull} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput3" className="form-label">URL</label>
                                    <input type="text" className="form-control" id="exampleFormControlInput3" value={this.state.url} onChange={this.setUrlPlaylist} disabled={this.state.urlNull} />
                                </div>
                            </Fragment>
                    }
                </DialogContent>

                <DialogActions>
                    <button className="btn btn-success" onClick={this.confirm}>Invia</button>
                    <button className="btn btn-info" onClick={toggle}>Annulla</button>
                </DialogActions>
            </Dialog>
        )
    }
}