import React, { Component, Fragment } from 'react'
import moment from 'moment';

import LicenceOfDay from './graph/licenceOfDay';
import LicenceOfMonth from './graph/licenceOfMonth';
import PaymentOfMonth from './graph/paymentOfMonth';
import PaymentOfDay from './graph/paymentOfDay';
import ExpiringPlayers from './graph/expiringPlayers';
import Platforms from './graph/platforms';
import Gains from './graph/gains';
import VpnCountry from './graph/vpnCountry';
import VpnDays from './graph/vpnDays';
import VpnMonths from './graph/vpnMonths';

import { returnNationality } from '../../common/actions/returnNationality';
import { API_PAYMENT_STATISTICS, API_PLAYER_STATISTICS, API_VPN_STATISTICS, API_USER_STATISTICS, API_VPN_GET_PEER } from '../../config';
import TagWidgetLive from './tagWidget/tagWidgetLive';
import { returnDays } from '../../common/actions/returnDays';
import { gains } from '../../common/actions/gains';
import { controlRole } from '../../common/actions/controlRole';
import { isEmpty } from '../../utility';
import axiosInstance from '../../axiosInstance';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // ------------------- VARIABILI PER GRAFICO 1 ---------------------- //
            totalUsers: 0, // totale utenti registrati
            signedUsers: 0, // utenti registrati che hanno confermato mail
            PayPalPayment: 0, // numero di pagamenti con paypal
            NetopiaPayment: 0, // numero di pagamenti con netopia
            StripePayment: 0, // numero di pagamenti con stripe
            BitPayPayment: 0, // numero di pagamenti con bitpay
            StarkPayment: 0, // numero di pagamenti con stark
            promoPayment: 0, // numero di pagamenti con Promo 100%
            manualPayment: 0, // numero di pagamenti con Promo 100%
            vpnLicenceActiveOneMonth: 0, // numero vpn licence 1 mese attive
            vpnLicenceActiveThreeMonth: 0, // numero vpn licence 3 mesi attive
            vpnLicenceActiveSixMonth: 0, // numero vpn licence 6 mesi attive
            vpnLicenceActiveTwelveMonth: 0, // numero vpn licence 12 mesi attive
            vpnLicenceActiveTwentyfourMonth: 0, // numero vpn licence 24 mesi attive
            vpnLicenceActiveThirtysixMonth: 0, // numero vpn licence 36 mesi attive
            totalLicencesLifetime: 0, // numero di licenze lifetime totali
            totalLicencesTrial: 0, // numero di licenze trial totali
            totalPlayers: 0, // numero di players totali
            samsungPlatform: 0, // numero di piattaforme samsung
            lgPlatform: 0, // numero di piattaforme LG
            android: 0, // numero di piattaforme Android
            numberVpnPeer: 0,
            loadingGraph1: true,

            // ------------------- VARIABILI PER GRAFICO 2 ---------------------- //
            labelDays: [], // array contenente labels delle licenze lifeTime (per giorno)
            valueDays: [], // array contenente values delle licenze lifeTime (per giorno)
            lifetimeMonth: moment().utc().get('month') + 1, // mese selezionato (grafico lifetime)
            loadingGraph2: true,

            // ------------------- VARIABILI PER GRAFICO 3 ---------------------- //
            trial: [], // numero di licenze trial
            lifeTime: [], // numero di licenze lifetime
            loadingGraph3: true,

            // ------------------- VARIABILI PER GRAFICO 4 ---------------------- //
            labelsNations: [], // array contenente labels dei giorni
            valueNations: [], // array contenente values delle nazioni (per giorno)
            monthPaired: moment().utc().get('month') + 1, // mese selezionato
            dayPaired: moment().utc().get('date'), // giorno selezionato
            loadingGraph4: true,

            // ------------------- VARIABILI PER GRAFICO 5 ---------------------- //
            labelsMonths: [], // array contenente labels delle nazioni
            valueMonths: [], // array contenente values delle nazioni (per mese)
            monthUnPaired: moment().utc().get('month') + 1, // mese selezionato (grafico Nazioni per mese)
            loadingGraph5: true,

            // ------------------- VARIABILI PER GRAFICO 6 ---------------------- //
            gainsChange: false,
            labelsGrossGainsMonths: [], // array contenente labels dei prodotti acquistati (ricavi lordi)
            valueGrossGainsMonths: [], // array contenente values dei prodotti acquistati (ricavi lordi)
            labelsNetGainsMonths: [], // array contenente labels dei prodotti acquistati (ricavi netti)
            valueNetGainsMonths: [], // array contenente values dei prodotti acquistati (ricavi netti)
            paymentIssuedAtStart: moment().utc(),
            paymentIssuedAtEnd: moment().utc().add(1, 'days'),
            loadingGraph6: true,

            // ------------------- VARIABILI PER GRAFICO 7 ---------------------- //
            trialPlatforms: [], // numero di licenze trial
            lifetimePlatforms: [], // numero di licenze lifetime
            playerIssuedAtStart: moment().utc(),
            playerIssuedAtEnd: moment().utc().add(1, 'days'),
            loadingGraph7: true,


            // ------------------- VARIABILI PER GRAFICO 10 ---------------------- //
            labelExpiringLicence: [], // array contenente labels delle licenze in scadenza
            valueExpiringLicence: [], // array contenente values delle licenze in scadenza
            trialMonth: moment().utc().get('month') + 1,  // mese selezionato (grafico licenza in scadenza)
            loadingGraph10: true,

            // ------------------- VARIABILI PER GRAFICO 11 ---------------------- //
            labelsVpnCountry: [], // array contenente labels dei prodotti acquistati
            vpnOneMonthCountry: [], // array contenente values delle licenze vpn 1 mese per Paese
            vpnThreeMonthsCountry: [], // array contenente values delle licenze vpn 3 mesi per Paese
            vpnSixMonthsCountry: [], // array contenente values delle licenze vpn 6 mesi per Paese
            vpnTwelveMonthsCountry: [], // array contenente values delle licenze vpn 12 mesi per Paese
            vpnTwentyfourMonthsCountry: [], // array contenente values delle licenze vpn 24 mesi per Paese
            vpnThirtysixMonthsCountry: [], // array contenente values delle licenze vpn 36 mesi per Paese
            loadingGraph11: true,

            // ------------------- VARIABILI PER GRAFICO 12 ---------------------- //
            vpnChange: false,
            vpnDays: moment().utc().get('month') + 1, // mese selezionato (grafico vpn per giorno)
            vpnOneMonthValue: [], // array contenente values delle licenze vpn 1 mese
            vpnThreeMonthsValue: [], // array contenente values delle licenze vpn 3 mesi
            vpnSixMonthsValue: [], // array contenente values delle licenze vpn 6 mesi
            vpnTwelveMonthsValue: [], // array contenente values delle licenze vpn 12 mesi
            vpnTwentyfourMonthsValue: [], // array contenente values delle licenze vpn 24 mesi
            vpnThirtysixMonthsValue: [], // array contenente values delle licenze vpn 36 mesi

            vpnThreeMonthsHwValue: [], // array contenente values delle vpnBox 3 mesi
            vpnSixMonthsHwValue: [], // array contenente values delle vpnBox 6 mesi
            vpnTwelveMonthsHwValue: [], // array contenente values delle vpnBox 12 mesi
            vpnTwentyfourMonthsHwValue: [], // array contenente values delle vpnBox 24 mesi
            vpnThirtysixMonthsHwValue: [], // array contenente values delle vpnBox 36 mesi
            loadingGraph12: true,

            // ------------------- VARIABILI PER GRAFICO 13 ---------------------- //
            vpnBoxChange: false,
            vpnOneDayValue: [],
            vpnThreeDaysValue: [],
            vpnSixDaysValue: [],
            vpnTwelveDaysValue: [],
            vpnTwentyfourDaysValue: [],
            vpnThirtysixDaysValue: [],

            vpnThreeDaysHwValue: [],
            vpnSixDaysHwValue: [],
            vpnTwelveDaysHwValue: [],
            vpnTwentyfourDaysHwValue: [],
            vpnThirtysixDaysHwValue: [],
            loadingGraph13: true,

            // anno selezionato (grafico Nazioni per giorno)
            year: moment().get('year'),

            stopUpdate: false
        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        const { role } = this.props;
        if (!isEmpty(role.player) || !isEmpty(role.vpnPass) || !isEmpty(role.user) || !isEmpty(role.payment)) {
            this.callAllStatistics();
        }
    }

    componentDidUpdate() {
        const { role } = this.props;
        if ((!isEmpty(role.player) || !isEmpty(role.vpnPass) || !isEmpty(role.user) || !isEmpty(role.payment)) && !this.state.stopUpdate) {
            this.callAllStatistics();
        }
    }

    /**
     * Chiama tutte le chiamate al BE per tutte le statistiche.
     */
    callAllStatistics = () => {
        const { role } = this.props;
        if (controlRole(role.payment, "api/Payment/Statistics", "GET")) this.getPaymentStatistics();
        if (controlRole(role.player, "api/Player/Statistics", "GET")) this.getPlayerStatistics();
        if (controlRole(role.vpnPass, "api/VpnPass/Statistics", "GET")) this.getVpnStatistics();
        if (controlRole(role.user, "api/User/Statistics", "GET")) this.getUserStatistics();
        if (controlRole(role.vpnPeer, "api/VpnPeer", "GET")) this.getVpnPeer();
        this.setState({ stopUpdate: true });
    }

    /**
     * Chiamata solo per GRAFICO 1
     * Restituisce le statistiche sugli utenti.
     */
    getUserStatistics = async () => {
        try {
            let response = await axiosInstance.get(`${API_USER_STATISTICS}`);
            this.setState({
                signedUsers: response.data.values.signedUser,
                totalUsers: response.data.values.totalUser,
                loadingGraph1: false
            });

        } catch (result) {
            this.setState({ loadingGraph1: false });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Error ' + result.response.status + ' in GET_USER_STATISTICS');
            }
        }
    }

    /**
     * Chiamata solo per GRAFICO 1
     * Restituisce le statistiche sul numero delle vpn peer.
     */
    getVpnPeer = async () => {
        try {
            let paramsData = {
                'elementsByPage': 1,
                'page': 1
            };
            let response = await axiosInstance.get(`${API_VPN_GET_PEER}`, { params: paramsData });
            this.setState({
                numberVpnPeer: response.data.info.total,
                loadingGraph1: false
            });

        } catch (result) {
            // this.setState({ loadingGraph1: false });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Error ' + result.response.status + ' in GET_VPN_PEER');
            }
        }
    }

    /**
     * Restituisce le statistiche sui player.
     * 
     * Utilizzato su :
     *  - GRAFICO 1
     *  - GRAFICO 2
     *  - GRAFICO 3
     *  - GRAFICO 7
     *  - GRAFICO 10
     */
    getPlayerStatistics = async () => {
        this.setState({
            loadingGraph2: true,
            loadingGraph3: true,
            loadingGraph7: true,
            loadingGraph10: true,
        });
        try {
            let params = {
                'lifeTimeMonth': this.state.lifetimeMonth,
                'trialMonth': this.state.trialMonth,
                'year': this.state.year,
                'filters[0][CreatedAtStart]': this.state.playerIssuedAtStart,
                'filters[1][CreatedAtEnd]': this.state.playerIssuedAtEnd,
            }

            let response = await axiosInstance.get(`${API_PLAYER_STATISTICS}`, { params: params });

            // -------- Chiamata per GRAFICO 2 --------
            this.sanitizeLifetimeDay(response.data.lifeTimeActivatedByDay);

            // -------- Chiamata per GRAFICO 10 --------
            this.sanitizeExpiringLicence(response.data.trialInExpirationByDay);

            this.setState({
                // -------- Chiamata per GRAFICO 3 --------
                trial: [
                    response.data.newTrialsByMonth.jan,
                    response.data.newTrialsByMonth.feb,
                    response.data.newTrialsByMonth.mar,
                    response.data.newTrialsByMonth.apr,
                    response.data.newTrialsByMonth.may,
                    response.data.newTrialsByMonth.jun,
                    response.data.newTrialsByMonth.jul,
                    response.data.newTrialsByMonth.aug,
                    response.data.newTrialsByMonth.sep,
                    response.data.newTrialsByMonth.oct,
                    response.data.newTrialsByMonth.nov,
                    response.data.newTrialsByMonth.dec
                ],
                lifeTime: [
                    response.data.lifeTimeActivatedByMonth.jan,
                    response.data.lifeTimeActivatedByMonth.feb,
                    response.data.lifeTimeActivatedByMonth.mar,
                    response.data.lifeTimeActivatedByMonth.apr,
                    response.data.lifeTimeActivatedByMonth.may,
                    response.data.lifeTimeActivatedByMonth.jun,
                    response.data.lifeTimeActivatedByMonth.jul,
                    response.data.lifeTimeActivatedByMonth.aug,
                    response.data.lifeTimeActivatedByMonth.sep,
                    response.data.lifeTimeActivatedByMonth.oct,
                    response.data.lifeTimeActivatedByMonth.nov,
                    response.data.lifeTimeActivatedByMonth.dec
                ],

                // -------- Chiamata per GRAFICO 7 -------- //
                trialPlatforms: [
                    response.data.trialsByPlatform["sectv-tizen"],
                    response.data.trialsByPlatform["tv-webos"],
                    response.data.trialsByPlatform.android

                ],
                lifetimePlatforms: [
                    response.data.licencesByPlatform["sectv-tizen"],
                    response.data.licencesByPlatform["tv-webos"],
                    response.data.licencesByPlatform.android

                ],

                // -------- Chiamata per GRAFICO 1 -------- //
                samsungPlatform: response.data.overAllLicencesByPlatform["sectv-tizen"],
                lgPlatform: response.data.overAllLicencesByPlatform["tv-webos"],
                android: response.data.overAllLicencesByPlatform.android,
                totalLicencesLifetime: response.data.overAll.Licences,
                totalLicencesTrial: response.data.overAll.Trials,
                totalPlayers: response.data.overAll.Players,

                loadingGraph2: false,
                loadingGraph3: false,
                loadingGraph7: false,
                loadingGraph10: false,
            });
        } catch (result) {
            this.setState({
                loadingGraph2: false,
                loadingGraph3: false,
                loadingGraph7: false,
                loadingGraph10: false,
            });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Error ' + result.response.status + ' in GET_PLAYER_STATISTICS');
            }
        }
    }

    sanitizeLifetimeDay = (data) => {
        let tempLabelDay = [];
        let tempValueDay = [];

        Object.keys(data)
            .sort((a, b) => a > b ? 1 : -1)
            // eslint-disable-next-line
            .map(el => {
                tempLabelDay.push(returnDays(el));
                tempValueDay.push(data[el]);
            });

        this.setState({
            labelDays: tempLabelDay,
            valueDays: tempValueDay
        });
    }

    /**
     * Restituisce le statistiche sui pagamenti.
     * Aggiunti controlli di fine, in modo da vedere i pagamenti sia mensili che giornalieri.
     * 
     * Utilizzato su:
     * - GRAFICO 1
     * - GRAFICO 4
     * - GRAFICO 5
     * - GRAFICO 6
     */
    getPaymentStatistics = async () => {
        this.setState({
            loadingGraph4: true,
            loadingGraph5: true,
            loadingGraph6: true,
            loadingGraph11: true,
        });
        try {
            let params = {
                'monthUnPaired': this.state.monthUnPaired,
                'monthPaired': this.state.monthPaired,
                'dayPaired': this.state.dayPaired,
                'year': this.state.year,
                'gains': this.state.gains,
                'filters[0][IssuedAtStart]': this.state.paymentIssuedAtStart,
                'filters[1][IssuedAtEnd]': this.state.paymentIssuedAtEnd,
            }

            let response = await axiosInstance.get(`${API_PAYMENT_STATISTICS}`, { params: params });

            // -------- Chiamata per GRAFICO 5 --------
            this.sanitizeMonth(response.data.usersByMonths);

            // // -------- Chiamata per GRAFICO 4 --------
            this.sanitizeDay(response.data.usersByDays);
            // // -------- Chiamata per GRAFICO 6 --------
            this.sanitizeGrossGainsMonth(response.data.grossGains);
            this.sanitizeNetGainsMonth(response.data.netGains);

            // // -------- Chiamata per GRAFICO 11 --------
            this.sanitizeVpnCountry(response.data.vpnPassesByCountry);

            this.setState({
                // -------- Chiamata per GRAFICO 1 --------
                PayPalPayment: response.data.providers.PayPal || 0,
                StripePayment: response.data.providers.Stripe || 0,
                BitPayPayment: response.data.providers.BitPay || 0,
                StarkPayment: response.data.providers.Stark || 0,
                NetopiaPayment: response.data.providers.Netopia_cc || 0,
                manualPayment: response.data.providers.Manual || 0,
                promoPayment: response.data.providers.NanoMidPromo || 0,

                loadingGraph4: false,
                loadingGraph5: false,
                loadingGraph6: false,
                loadingGraph11: false,
            });
        } catch (result) {
            this.setState({
                loadingGraph4: false,
                loadingGraph5: false,
                loadingGraph6: false,
                loadingGraph11: false,
            });
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Error ' + result.response.status + ' in GET_PAYMENT_STATISTICS');
            }
        }
    }

    /**
     * Riceve in input i dati dal server.
     * Pulisce i dati non bonificati ricevuti dal server e controlla se sono troppi stati da visualizzare,
     * in tal caso li accorpa in Other
     */
    sanitizeMonth = (data) => {
        let tempLabelMonth = [];
        let tempValueMonth = [];
        let tempLabelMonth2 = [];
        let tempValueMonth2 = [];
        let other = 0;

        // eslint-disable-next-line
        Object.keys(data).map(el => {
            // prendi solo i dati che hanno una nazionalità siglata (es. IT)
            if (returnNationality(el) !== "") {
                tempLabelMonth.push(returnNationality(el));
                tempValueMonth.push(data[el]);
            }
        });

        // se i dati ne sono più di 19, allora accorpa gli ultimi in un unico valore
        if (tempLabelMonth.length >= 19) {
            for (let i = 0; i <= 18; i++) {
                tempValueMonth2.push(tempValueMonth[i]);
                tempLabelMonth2.push(tempLabelMonth[i]);
            }

            for (let k = 19; k < tempLabelMonth.length; k++) {
                other += tempValueMonth[k];
            }

            tempValueMonth2.push(other);
            tempLabelMonth2.push("Altro");

            this.setState({
                labelsMonths: tempLabelMonth2,
                valueMonths: tempValueMonth2
            });
        }
        else {
            this.setState({
                labelsMonths: tempLabelMonth,
                valueMonths: tempValueMonth
            });
        }

    }

    sanitizeGrossGainsMonth = (data) => {
        let tempLabelGrossGainsMonth = [];
        let tempValueGrossGainsMonth = [];

        // eslint-disable-next-line
        Object.keys(data).map(el => {
            // prendi solo i dati che hanno una nazionalità siglata (es. IT)
            if (gains(el) !== "") {
                tempLabelGrossGainsMonth.push(gains(el));
                tempValueGrossGainsMonth.push(data[el]);
            }
        });

        this.setState({
            labelsGrossGainsMonths: tempLabelGrossGainsMonth,
            valueGrossGainsMonths: tempValueGrossGainsMonth
        });
    }

    sanitizeNetGainsMonth = (data) => {
        let tempLabelNetGainsMonth = [];
        let tempValueNetGainsMonth = [];

        // eslint-disable-next-line
        Object.keys(data).map(el => {
            // prendi solo i dati che hanno una nazionalità siglata (es. IT)
            if (gains(el) !== "") {
                tempLabelNetGainsMonth.push(gains(el));
                tempValueNetGainsMonth.push(data[el]);
            }
        });

        this.setState({
            labelsNetGainsMonths: tempLabelNetGainsMonth,
            valueNetGainsMonths: tempValueNetGainsMonth
        });
    }

    sanitizeDay = (data) => {
        let tempLabelNation = [];
        let tempValueNation = [];

        // eslint-disable-next-line
        Object.keys(data).map(el => {
            if (returnNationality(el) !== "") {
                tempLabelNation.push(returnNationality(el));
                tempValueNation.push(data[el]);
            }
        });

        this.setState({
            labelsNations: tempLabelNation,
            valueNations: tempValueNation
        });
    }

    sanitizeExpiringLicence = (data) => {
        let tempLabelExpiringLicence = [];
        let tempValueExpiringLicence = [];

        Object.keys(data)
            .sort((a, b) => a > b ? 1 : -1)
            // eslint-disable-next-line
            .map(el => {
                tempLabelExpiringLicence.push(returnDays(el));
                tempValueExpiringLicence.push(data[el]);
            });

        this.setState({
            labelExpiringLicence: tempLabelExpiringLicence,
            valueExpiringLicence: tempValueExpiringLicence
        });
    }

    /**
     * Riceve in input i dati dal server.
     * Pulisce i dati non bonificati ricevuti dal server e controlla se sono troppi stati da visualizzare,
     * in tal caso li accorpa in Other
     */
    sanitizeVpnCountry = (data) => {
        let tempValueOne = [];
        let tempValueThree = [];
        let tempValueSix = [];
        let tempValueTwelve = [];
        let tempValueTwentyfour = [];
        let tempValueThirtysix = [];
        let labelValue = [];

        let tempValueOne2 = [];
        let tempValueThree2 = [];
        let tempValueSix2 = [];
        let tempValueTwelve2 = [];
        let tempValueTwentyfour2 = [];
        let tempValueThirtysix2 = [];
        let labelValue2 = [];
        let other = 0;
        let other1 = 0;
        let other2 = 0;
        let other3 = 0;
        let other4 = 0;
        let other5 = 0;
        let j = 0;

        // eslint-disable-next-line
        data.map(el => {
            // // se i dati ne sono più di 19, allora accorpa gli ultimi in un unico valore
            labelValue.push(returnNationality(el.id));
            // eslint-disable-next-line
            el.products.map(elem => {
                switch (elem.product) {
                    case "VPN-ONE-MONTH": tempValueOne.push(elem.counter); break;
                    case "VPN-THREE-MONTHS": tempValueThree.push(elem.counter); break;
                    case "VPN-SIX-MONTHS": tempValueSix.push(elem.counter); break;
                    case "VPN-TWELVE-MONTHS": tempValueTwelve.push(elem.counter); break;
                    case "VPN-TWENTYFOUR-MONTHS": tempValueTwentyfour.push(elem.counter); break;
                    case "VPN-THIRTYSIX-MONTHS": tempValueThirtysix.push(elem.counter); break;
                    default: break;
                }
            });

            if (tempValueOne[j] === undefined) {
                tempValueOne.push(0);
            }
            if (tempValueThree[j] === undefined) {
                tempValueThree.push(0);
            }
            if (tempValueSix[j] === undefined) {
                tempValueSix.push(0);
            }
            if (tempValueTwelve[j] === undefined) {
                tempValueTwelve.push(0);
            }
            if (tempValueTwentyfour[j] === undefined) {
                tempValueTwentyfour.push(0);
            }
            if (tempValueThirtysix[j] === undefined) {
                tempValueThirtysix.push(0);
            }
            j++;
        });

        if (labelValue.length >= 19) {
            for (let i = 0; i <= 18; i++) {
                tempValueOne2.push(tempValueOne[i]);
                tempValueThree2.push(tempValueThree[i]);
                tempValueSix2.push(tempValueSix[i]);
                tempValueTwelve2.push(tempValueTwelve[i]);
                tempValueTwentyfour2.push(tempValueTwentyfour[i]);
                tempValueThirtysix2.push(tempValueThirtysix[i]);
                labelValue2.push(labelValue[i]);
            }

            for (let k = 19; k < labelValue.length; k++) {
                other += tempValueOne[k];
                other1 += tempValueThree[k];
                other2 += tempValueSix[k];
                other3 += tempValueTwelve[k];
                other4 += tempValueTwentyfour[k];
                other5 += tempValueThirtysix[k];
            }

            tempValueOne2.push(other);
            tempValueThree2.push(other1);
            tempValueSix2.push(other2);
            tempValueTwelve2.push(other3);
            tempValueTwentyfour2.push(other4);
            tempValueThirtysix2.push(other5);
            labelValue2.push("Altro");

            this.setState({
                labelsVpnCountry: labelValue2,
                vpnOneMonthCountry: tempValueOne2,
                vpnThreeMonthsCountry: tempValueThree2,
                vpnSixMonthsCountry: tempValueSix2,
                vpnTwelveMonthsCountry: tempValueTwelve2,
                vpnTwentyfourMonthsCountry: tempValueTwentyfour2,
                vpnThirtysixMonthsCountry: tempValueThirtysix2,
            });
        }
        else {
            this.setState({
                labelsVpnCountry: labelValue,
                vpnOneMonthCountry: tempValueOne,
                vpnThreeMonthsCountry: tempValueThree,
                vpnSixMonthsCountry: tempValueSix,
                vpnTwelveMonthsCountry: tempValueTwelve,
                vpnTwentyfourMonthsCountry: tempValueTwentyfour,
                vpnThirtysixMonthsCountry: tempValueThirtysix,
            });
        }
    }

    /**
     * Restituisce le statistiche sulla VPN.
     * 
     * Utilizzato su:
     * - GRAFICO 1
     * - GRAFICO 8
     * - GRAFICO 9
     * - GRAFICO 12
     * - GRAFICO 13
     */
    getVpnStatistics = async () => {
        this.setState({
            loadingGraph8: true,
            loadingGraph9: true,
            loadingGraph12: true,
            loadingGraph13: true
        })
        try {
            let params = {
                month: this.state.vpnDays,
                year: this.state.year,
            }

            let response = await axiosInstance.get(`${API_VPN_STATISTICS}`, { params: params });

            // -------- Chiamata per GRAFICO 9 --------
            this.sanitizeVpnDays(response.data.vpnPassesByDays);

            // -------- Chiamata per GRAFICO 8 --------
            this.sanitizeVpnMonth(response.data.vpnPassesByMonth);

            // -------- Chiamata per GRAFICO 1 --------
            this.totalActiveVpn(response.data.vpnPassesActiveTotal);

            this.setState({
                loadingGraph8: false,
                loadingGraph9: false,
                loadingGraph12: false,
                loadingGraph13: false
            })

        } catch (result) {
            this.setState({
                loadingGraph8: false,
                loadingGraph9: false,
                loadingGraph12: false,
                loadingGraph13: false
            })
            if (result && result.response && result.response.status) {
                this.props.errorNotify('Error ' + result.response.status + ' in GET_VPN_STATISTICS');
            }
        }
    }

    sanitizeVpnDays = (data) => {

        // LICENZE RINNOVO VPN
        let tempValueDayOneVpn = [];
        let tempValueDayThreeVpn = [];
        let tempValueDaySixVpn = [];
        let tempValueDayTwelveVpn = [];
        let tempValueDayTwentyfourVpn = [];
        let tempValueDayThirtysixVpn = [];

        // VPN BOX HARDWARE
        let tempValueDayThreeVpnHw = [];
        let tempValueDaySixVpnHw = [];
        let tempValueDayTwelveVpnHw = [];
        let tempValueDayTwentyfourVpnHw = [];
        let tempValueDayThirtysixVpnHw = [];

        // eslint-disable-next-line
        data.map(el => {
            // eslint-disable-next-line
            el.products.map(elem => {
                switch (elem.product) {

                    // LICENZE VPN PASS
                    case "VPN-ONE-MONTH": tempValueDayOneVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-THREE-MONTHS": tempValueDayThreeVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-SIX-MONTHS": tempValueDaySixVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-TWELVE-MONTHS": tempValueDayTwelveVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-TWENTYFOUR-MONTHS": tempValueDayTwentyfourVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-THIRTYSIX-MONTHS": tempValueDayThirtysixVpn.push({ [el.id]: elem.counter }); break;

                    // VPN BOX HARDWARE
                    case "VPNBOX-THREE-MONTH-HW": tempValueDayThreeVpnHw.push({ [el.id]: elem.counter }); break;
                    case "VPNBOX-SIX-MONTH-HW": tempValueDaySixVpnHw.push({ [el.id]: elem.counter }); break;
                    case "VPNBOX-TWELVE-MONTH-HW": tempValueDayTwelveVpnHw.push({ [el.id]: elem.counter }); break;
                    case "VPNBOX-TWENTYFOUR-MONTH-HW": tempValueDayTwentyfourVpnHw.push({ [el.id]: elem.counter }); break;
                    case "VPNBOX-THIRTYSIX-MONTH-HW": tempValueDayThirtysixVpnHw.push({ [el.id]: elem.counter }); break;
                    default: break;
                }
            });
        });

        this.setState({

            // LICENZE VPN PASS
            vpnOneDayValue: tempValueDayOneVpn,
            vpnThreeDaysValue: tempValueDayThreeVpn,
            vpnSixDaysValue: tempValueDaySixVpn,
            vpnTwelveDaysValue: tempValueDayTwelveVpn,
            vpnTwentyfourDaysValue: tempValueDayTwentyfourVpn,
            vpnThirtysixDaysValue: tempValueDayThirtysixVpn,

            // VPN BOX HARDWARE
            vpnThreeDaysHwValue: tempValueDayThreeVpnHw,
            vpnSixDaysHwValue: tempValueDaySixVpnHw,
            vpnTwelveDaysHwValue: tempValueDayTwelveVpnHw,
            vpnTwentyfourDaysHwValue: tempValueDayTwentyfourVpnHw,
            vpnThirtysixDaysHwValue: tempValueDayThirtysixVpnHw,
        });
    }

    sanitizeVpnMonth = (data) => {

        // LICENZE VPN PASS
        let tempValueOneVpn = [];
        let tempValueThreeVpn = [];
        let tempValueSixVpn = [];
        let tempValueTwelveVpn = [];
        let tempValueTwentyfourVpn = [];
        let tempValueThirtysixVpn = [];

        // VPN BOX HARDWARE
        let tempValueOneVpnHw = [];
        let tempValueThreeVpnHw = [];
        let tempValueSixVpnHw = [];
        let tempValueTwelveVpnHw = [];
        let tempValueTwentyfourVpnHw = [];
        let tempValueThirtysixVpnHw = [];

        // eslint-disable-next-line
        data.map(el => {
            // eslint-disable-next-line
            el.products.map(elem => {
                switch (elem.product) {

                    // LICENZE VPN PASS
                    case "VPN-ONE-MONTH": tempValueOneVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-THREE-MONTHS": tempValueThreeVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-SIX-MONTHS": tempValueSixVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-TWELVE-MONTHS": tempValueTwelveVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-TWENTYFOUR-MONTHS": tempValueTwentyfourVpn.push({ [el.id]: elem.counter }); break;
                    case "VPN-THIRTYSIX-MONTHS": tempValueThirtysixVpn.push({ [el.id]: elem.counter }); break;

                    // VPN BOX HARDWARE
                    case "VPNBOX-THREE-MONTH-HW": tempValueThreeVpnHw.push({ [el.id]: elem.counter }); break;
                    case "VPNBOX-SIX-MONTH-HW": tempValueSixVpnHw.push({ [el.id]: elem.counter }); break;
                    case "VPNBOX-TWELVE-MONTH-HW": tempValueTwelveVpnHw.push({ [el.id]: elem.counter }); break;
                    case "VPNBOX-TWENTYFOUR-MONTH-HW": tempValueTwentyfourVpnHw.push({ [el.id]: elem.counter }); break;
                    case "VPNBOX-THIRTYSIX-MONTH-HW": tempValueThirtysixVpnHw.push({ [el.id]: elem.counter }); break;
                    default: break;
                }
            });
        });

        this.setState({

            // LICENZE VPN PASS
            vpnOneMonthValue: tempValueOneVpn,
            vpnThreeMonthsValue: tempValueThreeVpn,
            vpnSixMonthsValue: tempValueSixVpn,
            vpnTwelveMonthsValue: tempValueTwelveVpn,
            vpnTwentyfourMonthsValue: tempValueTwentyfourVpn,
            vpnThirtysixMonthsValue: tempValueThirtysixVpn,

            // VPN BOX HARDWARE
            vpnOneMonthHwValue: tempValueOneVpnHw,
            vpnThreeMonthsHwValue: tempValueThreeVpnHw,
            vpnSixMonthsHwValue: tempValueSixVpnHw,
            vpnTwelveMonthsHwValue: tempValueTwelveVpnHw,
            vpnTwentyfourMonthsHwValue: tempValueTwentyfourVpnHw,
            vpnThirtysixMonthsHwValue: tempValueThirtysixVpnHw,

        });
    }

    totalActiveVpn = (data) => {
        // eslint-disable-next-line
        Object.keys(data).map((elem) => {
            switch (elem) {

                // LICENZE VPN PASS
                case "VPN-ONE-MONTH": this.setState({ vpnLicenceActiveOneMonth: data[elem] }); break;
                case "VPN-THREE-MONTHS": this.setState({ vpnLicenceActiveThreeMonth: data[elem] }); break;
                case "VPN-SIX-MONTHS": this.setState({ vpnLicenceActiveSixMonth: data[elem] }); break;
                case "VPN-TWELVE-MONTHS": this.setState({ vpnLicenceActiveTwelveMonth: data[elem] }); break;
                case "VPN-TWENTYFOUR-MONTHS": this.setState({ vpnLicenceActiveTwentyfourMonth: data[elem] }); break;
                case "VPN-THIRTYSIX-MONTHS": this.setState({ vpnLicenceActiveThirtysixMonth: data[elem] }); break;
                default: break;
            }
        });
    }

    onChange = (event) => {
        let name = event.target.name;
        this.setState({ [event.target.name]: parseInt(event.target.value) });

        if (event.target.name === 'vpnChange') {
            this.setState({ vpnChange: event.target.value === "true" ? true : false });
        }

        if (event.target.name === 'gainsChange') {
            this.setState({ gainsChange: event.target.value === "true" ? true : false });
        }

        if (event.target.name === 'vpnBoxChange') {
            this.setState({ vpnBoxChange: event.target.value === "true" ? true : false });
        }

        setTimeout(() => {
            // eslint-disable-next-line
            switch (name) {
                case 'trialMonth': case 'lifetimeMonth': case 'products': this.getPlayerStatistics(); break;
                case 'monthPaired': case 'monthUnPaired': case 'dayPaired': case 'gains': case 'gainsChange': this.getPaymentStatistics(); break;
                case 'year': this.callAllStatistics(); break;
                case 'vpnDays': case 'vpnChange': case 'vpnBoxChange': this.getVpnStatistics(); break;
                default: break;
            }
        }, 300);
    }

    // Usato per GRAFICO 6,11
    setDateIssuedStartChange = (newDate) => {
        this.setState({
            paymentIssuedAtStart: newDate,
        });
        setTimeout(() => {
            this.getPaymentStatistics();
        }, 300);
    }

    // Usato per GRAFICO 6,11
    setDateIssuedEndChange = (newDate) => {
        this.setState({
            paymentIssuedAtEnd: newDate,
        });
        setTimeout(() => {
            this.getPaymentStatistics();
        }, 300);
    }

    // Usato per GRAFICO 7
    setDateIssuedStartPlayerChange = (newDate) => {
        this.setState({ playerIssuedAtStart: newDate });
        setTimeout(() => {
            this.getPlayerStatistics();
        }, 300);
    }

    // Usato per GRAFICO 7
    setDateIssuedEndPlayerChange = (newDate) => {
        this.setState({ playerIssuedAtEnd: newDate });
        setTimeout(() => {
            this.getPlayerStatistics();
        }, 300);
    }

    /**
     * Funzione che crea in automatico ogni anno dei bottoni per sceglire l'anno per i filtri.
     * Partono dal 2019 fino all'anno in corso.
     * @returns 
     */
    buttonsYear = () => {
        let arrayButton = [];
        for (let i = 2022; i <= moment().get('year'); i++) {
            arrayButton.push(
                <button
                    name="year"
                    type="button"
                    value={i}
                    key={i}
                    className={this.state.year === i ? "btn btn-primary" : "btn btn-outline-primary"}
                    onClick={this.onChange}
                >
                    {i}
                </button>
            )
        }
        return arrayButton;
    }

    render() {
        const { role, isMobile } = this.props;
        return (
            <Fragment>
                <div className="row justify-content-center containerWidget">
                    <div className="col-xl-12 col-md-12 col-sm-8 col-12">
                        {/* GRAFICO 1 */}
                        <TagWidgetLive
                            loadingGraph1={this.state.loadingGraph1}
                            totalUsers={this.state.totalUsers}
                            signedUsers={this.state.signedUsers}

                            totalLicences={this.state.totalLicencesTrial + this.state.totalLicencesLifetime}
                            totalLicencesLifetime={this.state.totalLicencesLifetime}
                            totalLicencesTrial={this.state.totalLicencesTrial}
                            totalPlayers={this.state.totalPlayers}

                            samsung={this.state.samsungPlatform}
                            lg={this.state.lgPlatform}
                            android={this.state.android}

                            totalPayment={
                                this.state.PayPalPayment +
                                this.state.StripePayment +
                                this.state.BitPayPayment +
                                this.state.StarkPayment +
                                this.state.NetopiaPayment +
                                this.state.manualPayment +
                                this.state.promoPayment
                            }
                            paymentPayPal={this.state.PayPalPayment}
                            paymentStripe={this.state.StripePayment}
                            paymentBitPay={this.state.BitPayPayment}
                            paymentStark={this.state.StarkPayment}
                            paymentPromo={this.state.promoPayment}
                            paymentNetopia={this.state.NetopiaPayment}
                            paymentManual={this.state.manualPayment}

                            // LICENZE VPN PASS
                            vpnLicenceActiveOneMonth={this.state.vpnLicenceActiveOneMonth}
                            vpnLicenceActiveThreeMonth={this.state.vpnLicenceActiveThreeMonth}
                            vpnLicenceActiveSixMonth={this.state.vpnLicenceActiveSixMonth}
                            vpnLicenceActiveTwelveMonth={this.state.vpnLicenceActiveTwelveMonth}
                            vpnLicenceActiveTwentyfourMonth={this.state.vpnLicenceActiveTwentyfourMonth}

                            numberVpnPeer={this.state.numberVpnPeer}

                            isMobile={isMobile}
                            role={role}
                        />
                    </div>
                </div>

                <div className="row justify-content-center" style={{ margin: '2vh' }}>
                    <div className="col-12 text-center buttonsYear">
                        {this.buttonsYear()}
                    </div>
                </div>

                <div className="row justify-content-center">
                    {
                        controlRole(role.player, "api/Player/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 2 - Player */}
                            <LicenceOfDay
                                labelDays={this.state.labelDays}
                                valueDays={this.state.valueDays}
                                lifetimeMonth={this.state.lifetimeMonth}
                                loadingGraph2={this.state.loadingGraph2}
                                onChange={this.onChange}
                            />
                        </div>
                    }

                    {
                        controlRole(role.player, "api/Player/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 3 - Player */}
                            <LicenceOfMonth
                                trial={this.state.trial}
                                lifeTime={this.state.lifeTime}
                                loadingGraph3={this.state.loadingGraph3}
                            />
                        </div>
                    }
                </div>

                <div className="row justify-content-center">
                    {
                        controlRole(role.payment, "api/Payment/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 4 - Pagamenti */}
                            <PaymentOfMonth
                                labelsNations={this.state.labelsNations}
                                valueNations={this.state.valueNations}

                                dayPaired={this.state.dayPaired}
                                monthPaired={this.state.monthPaired}

                                onChange={this.onChange}
                                loadingGraph4={this.state.loadingGraph4}
                            />
                        </div>
                    }
                    {
                        controlRole(role.payment, "api/Payment/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 5 - Pagamenti */}
                            <PaymentOfDay
                                labelsMonths={this.state.labelsMonths}
                                valueMonths={this.state.valueMonths}
                                monthUnPaired={this.state.monthUnPaired}

                                onChange={this.onChange}
                                loadingGraph5={this.state.loadingGraph5}
                            />
                        </div>
                    }
                </div>

                <div className="row justify-content-center">
                    {
                        controlRole(role.payment, "api/Payment/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 6 - Pagamenti */}
                            <Gains
                                gainsChange={this.state.gainsChange}

                                // LORDI
                                labelsGrossGainsMonths={this.state.labelsGrossGainsMonths}
                                valueGrossGainsMonths={this.state.valueGrossGainsMonths}

                                // NETTI
                                labelsNetGainsMonths={this.state.labelsNetGainsMonths}
                                valueNetGainsMonths={this.state.valueNetGainsMonths}

                                setDateIssuedStartChange={this.setDateIssuedStartChange}
                                setDateIssuedEndChange={this.setDateIssuedEndChange}

                                paymentIssuedAtStart={this.state.paymentIssuedAtStart}
                                paymentIssuedAtEnd={this.state.paymentIssuedAtEnd}

                                onChange={this.onChange}
                                loadingGraph6={this.state.loadingGraph6}
                            />
                        </div>
                    }

                    {
                        controlRole(role.player, "api/Player/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 7 - Acquisti per piattaforma */}
                            <Platforms
                                trialPlatforms={this.state.trialPlatforms}
                                lifetimePlatforms={this.state.lifetimePlatforms}

                                setDateIssuedStartPlayerChange={this.setDateIssuedStartPlayerChange}
                                setDateIssuedEndPlayerChange={this.setDateIssuedEndPlayerChange}

                                playerIssuedAtStart={this.state.playerIssuedAtStart}
                                playerIssuedAtEnd={this.state.playerIssuedAtEnd}

                                loadingGraph7={this.state.loadingGraph7}
                            />
                        </div>
                    }
                </div>

                <div className="row justify-content-center">
                    {
                        controlRole(role.player, "api/Player/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 10 - Player */}
                            <ExpiringPlayers
                                labelExpiringLicence={this.state.labelExpiringLicence}
                                valueExpiringLicence={this.state.valueExpiringLicence}
                                trialMonth={this.state.trialMonth}

                                onChange={this.onChange}
                                loadingGraph10={this.state.loadingGraph10}
                            />
                        </div>
                    }

                    {
                        controlRole(role.payment, "api/Payment/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 11 - VPN Pass */}
                            <VpnCountry
                                labelsVpnCountry={this.state.labelsVpnCountry}

                                vpnOneMonthCountry={this.state.vpnOneMonthCountry}
                                vpnThreeMonthsCountry={this.state.vpnThreeMonthsCountry}
                                vpnSixMonthsCountry={this.state.vpnSixMonthsCountry}
                                vpnTwelveMonthsCountry={this.state.vpnTwelveMonthsCountry}
                                // vpnTwentyfourMonthsCountry={this.state.vpnTwentyfourMonthsCountry}
                                // vpnThirtysixMonthsCountry={this.state.vpnThirtysixMonthsCountry}

                                setDateIssuedStartVpnChange={this.setDateIssuedStartChange}
                                setDateIssuedEndVpnChange={this.setDateIssuedEndChange}

                                paymentIssuedAtStart={this.state.paymentIssuedAtStart}
                                paymentIssuedAtEnd={this.state.paymentIssuedAtEnd}

                                loadingGraph11={this.state.loadingGraph11}
                            />
                        </div>
                    }
                </div>

                <div className="row justify-content-center">
                    {
                        controlRole(role.vpnPass, "api/VpnPass/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 12 - Vpn Box */}
                            <VpnMonths
                                vpnChange={this.state.vpnChange}

                                vpnOneMonthValue={this.state.vpnOneMonthValue}
                                vpnThreeMonthsValue={this.state.vpnThreeMonthsValue}
                                vpnSixMonthsValue={this.state.vpnSixMonthsValue}
                                vpnTwelveMonthsValue={this.state.vpnTwelveMonthsValue}
                                vpnTwentyfourMonthsValue={this.state.vpnTwentyfourMonthsValue}

                                vpnThreeMonthsHwValue={this.state.vpnThreeMonthsHwValue}
                                vpnSixMonthsHwValue={this.state.vpnSixMonthsHwValue}
                                vpnTwelveMonthsHwValue={this.state.vpnTwelveMonthsHwValue}
                                vpnTwentyfourMonthsHwValue={this.state.vpnTwentyfourMonthsHwValue}
                                vpnThirtysixMonthsHwValue={this.state.vpnThirtysixMonthsHwValue}

                                onChange={this.onChange}
                                loadingGraph12={this.state.loadingGraph12}
                            />
                        </div>
                    }
                    {
                        controlRole(role.vpnPass, "api/VpnPass/Statistics", "GET") &&
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 text-center">
                            {/* GRAFICO 13 - Vpn Box */}
                            <VpnDays
                                vpnDays={this.state.vpnDays}
                                vpnBoxChange={this.state.vpnBoxChange}

                                vpnOneDayValue={this.state.vpnOneDayValue}
                                vpnThreeDaysValue={this.state.vpnThreeDaysValue}
                                vpnSixDaysValue={this.state.vpnSixDaysValue}
                                vpnTwelveDaysValue={this.state.vpnTwelveDaysValue}

                                vpnThreeDaysHwValue={this.state.vpnThreeDaysHwValue}
                                vpnSixDaysHwValue={this.state.vpnSixDaysHwValue}
                                vpnTwelveDaysHwValue={this.state.vpnTwelveDaysHwValue}
                                vpnTwentyfourDaysHwValue={this.state.vpnTwentyfourDaysHwValue}

                                onChange={this.onChange}
                                loadingGraph13={this.state.loadingGraph13}
                            />
                        </div>
                    }
                </div>
            </Fragment>
        )
    }
}