import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import Loading from '../../common/loading';
import FormFilter from '../components/formFilters';
import IconLicence from '../../common/iconLicence';
import './style.scss';
import Date from '../../common/date';
import ExpanableComponent from '../components/expanableComponent';
import ActionMenu from '../components/actionMenu';
import Modal from '../../common/modal';
import ModalChangePayment from '../../common/modal/modalChangePayment';
import ModalCreatePayment from '../../common/modal/modalCreatePayment';
import ModalRefundPayment from '../../common/modal/modalRefundPayment';
import { controlRole } from '../../common/actions/controlRole';
import SelectorCopy from '../../common/selectorCopy';
import { withNavigate } from '../../utility';

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalChangePayment: false,
            modalDeletePayment: false,
            modalDeactiveSub: false,
            modalCreatePayment: false,
            modalRefundPayment: false,

            // info usate per le informazioni del pagamento
            paymentId: '',
            deviceId: '',
            paymentTemp: {},

            hasClickModalChange: false,
            hasClickModalCreatePayment: false,
            hasClickModalDeletePayment: false,
            hasClickModalDeactiveSub: false,
            hasClickModalRefundPayment: false,

            loadingForRole: true
        }
    }

    /**
     * Il mount e l'update sono uguali, poiche se navigo tra i link di react router, dopo aver
     * caricato questa pagina, esegue solo il mount, mentre se refresho la pagina carica l'update.
     * 
     * (Esempio: se vado una prima volta sulla pagina utenti, dopo il mount carica update.
     * Se dopo il caricamento, senza refreshare, navigo e vado ad altre parti, esempio player, e poi ritorno,
     * react carica solo il mount e nemmeno una volta update.)
     */
    componentDidMount() {
        // se l'utente non ha nessun permesso per visualizzare i pagamenti, viene rispedito alla home
        if (!isEmpty(this.props.role.payment)) {
            if (!controlRole(this.props.role.payment, "api/Payment", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare i pagamenti!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    // richiama la lista dei prodotti, in modo che se crea un nuovo pagamento, posso selezionare il prodotto che voglio
                    this.props.getProductList();
                }
            }
        }
    }

    componentDidUpdate() {
        // se l'utente non ha nessun permesso per visualizzare i pagamenti, viene rispedito alla home
        if (!isEmpty(this.props.role.payment)) {
            if (!controlRole(this.props.role.payment, "api/Payment", "GET")) {
                this.props.handleNavigation(this.props.navigate, `/`);
                this.props.infoNotify('Non hai nessun permesso per visualizzare i pagamenti!')
            } else {
                if (this.state.loadingForRole) {
                    this.setState({ loadingForRole: false });
                    // richiama la lista dei prodotti, in modo che se crea un nuovo pagamento, posso selezionare il prodotto che voglio
                    this.props.getProductList();
                }
            }
        }

        if (!localStorage.token) {
            this.props.handleNavigation(this.props.navigate, '/');
        }
    }

    /**
     * Quando l'utente clicca per far aprire la modale di cambio pagamento.
     * 
     * @param data contiene l'object di tutto il pagamento
     */
    hasClickModalChange = (data) => {
        this.setState({
            deviceId: data.deviceId,
            paymentId: data.paymentId,
            providerTransactionId: data.providerTransactionId,
            hasClickModalChange: true
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalChangePayment: !prevState.modalChangePayment }));
        }, 200);
    }

    /**
     * Quando l'utente clicca per creare un nuovo pagamento.
     */
    hasClickModalCreatePayment = () => {
        this.setState({
            hasClickModalCreatePayment: true
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalCreatePayment: !prevState.modalCreatePayment }));
        }, 200);
    }

    /**
     * Quando l'utente clicca per eliminare un pagamento.
     */
    hasClickModalDeactiveSub = (id) => {
        this.setState({
            hasClickModalDeactiveSub: true,
            paymentId: id
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalDeactiveSub: !prevState.modalDeactiveSub }));
        }, 200);
    }

    /**
     * Quando l'utente clicca per eliminare un pagamento.
     */
    hasClickModalDeletePayment = (id) => {
        this.setState({
            hasClickModalDeletePayment: true,
            paymentId: id
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalDeletePayment: !prevState.modalDeletePayment }));
        }, 200);
    }

    /**
     * Quando l'utente clicca per eliminare un pagamento.
     */
    hasClickModalRefundPayment = (data) => {
        this.setState({
            hasClickModalRefundPayment: true,
            paymentTemp: data
        });
        setTimeout(() => {
            this.setState(prevState => ({ modalRefundPayment: !prevState.modalRefundPayment }));
        }, 200);
    }

    toggleModal = () => {
        this.setState({
            hasClickModalChange: false,
            hasClickModalCreatePayment: false,
            hasClickModalDeletePayment: false,
            hasClickModalDeactiveSub: false,
            hasClickModalRefundPayment: false,
            modalDeletePayment: false,
            modalDeactiveSub: false,
            modalChangePayment: false,
            modalCreatePayment: false,
            modalRefundPayment: false,
            deviceId: '',
            paymentId: '',
            paymentTemp: {}
        });
    }

    updateDevicePayment = (data) => {
        this.props.updateDevicePayment(data);
        this.toggleModal();
    }

    render() {
        const {
            role, payment, preferences,
            setIdVpnPassForDetail, getPaymentList, setIdPaymentForDetail, setLoadingForPayment, refundPayment,
            errorNotify, successNotify, infoNotify, warningNotify
        } = this.props;

        const columns = [
            {
                name: '',
                selector: row =>
                    // Pagato
                    row.paymentStatus === 1 ?
                        <span className="iconLifetime">
                            <i className="far fa-thumbs-up"></i>
                        </span>
                        :
                        // Rimborsato
                        row.paymentStatus === 3 ?
                            <span className="iconRefund">
                                <i className="fas fa-undo-alt"></i>
                            </span>
                            :
                            // Rimborsato Parzialmente
                            row.paymentStatus === 6 ?
                                <span className="iconPartialRefund">
                                    <i className="fas fa-undo-alt"></i>
                                </span>
                                :
                                // Cancellato / Respinto
                                row.paymentStatus === -1 ?
                                    <span className="iconTrial">
                                        <i className="far fa-thumbs-down"></i>
                                    </span>
                                    :
                                    // Non confermato / In attesa
                                    row.paymentStatus === 2 ?
                                        <span className="iconWait">
                                            <i className="fas fa-clock"></i>
                                        </span>
                                        :
                                        // Non Pagato / Errore
                                        row.paymentStatus === 0 ?
                                            <span className="iconTrial">
                                                <i className="fas fa-times"></i>
                                            </span>
                                            :
                                            // Sottoscrizione Attiva
                                            row.paymentStatus === 4 ?
                                                <span className="iconSub">
                                                    <i className="far fa-thumbs-up"></i>
                                                </span>
                                                :
                                                // Sottoscrizione Annullata
                                                row.paymentStatus === 5 ?
                                                    <span className="iconNotSub">
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                    :
                                                    '',
                sortable: false,
                width: '80px',
                center: true
            },
            {
                name: 'ID Pagamento',
                selector: row => <SelectorCopy item={row._id} infoNotify={this.props.infoNotify} />
            },
            {
                name: 'Utente',
                selector: row => row.invoiceAddress.name + ' ' + row.invoiceAddress.surname
            },
            {
                name: 'Creato il',
                selector: row => <Date format="L" date={row.createdAt} />
            },
            {
                name: 'Numero Fattura',
                selector: row => isNull(row.invoiceUrl) ? "Non esiste fattura" : row.invoiceNumber === row._id ? 'F-Promo' : <SelectorCopy item={row.invoiceNumber} infoNotify={this.props.infoNotify} />
            },
            {
                selector: row =>
                    <ActionMenu
                        hasClickModalRefundPayment={this.hasClickModalRefundPayment}
                        hasClickModalDeletePayment={this.hasClickModalDeletePayment}
                        hasClickModalDeactiveSub={this.hasClickModalDeactiveSub}
                        data={this.props.getPaymentList}
                        payment={row}
                        role={role}
                    />,
                right: true,
                button: true,
                allowOverflow: true,
                wrap: true
            },
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '50px',
                }
            },
            headCells: {
                style: {
                    paddingLeft: '8px',
                    fontWeight: 600,
                },
            },
            headRow: {
                style: {
                    minHeight: '35px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px',
                },
            },
        };

        return (
            <Fragment>
                {
                    this.state.loadingForRole ? <Loading /> :
                        <Fragment>
                            {(payment.isLoading || payment.setIsLoading) && <Loading />}

                            <div className="row">
                                <div className="col-12 paymentDesktop">
                                    <div className="contentFilterSearch">
                                        <FormFilter
                                            getPaymentList={getPaymentList}
                                            setLoadingForPayment={setLoadingForPayment}
                                            setIdPaymentForDetail={setIdPaymentForDetail}

                                            warningNotify={warningNotify}
                                            errorNotify={errorNotify}
                                            infoNotify={infoNotify}
                                            successNotify={successNotify}

                                            payment={payment}
                                            role={role}
                                            preferences={preferences}
                                        />
                                    </div>

                                    <div className="contentUser">
                                        <div className="row titleMobile">
                                            <div className="col-6">
                                                <h2 className='inlineBlock'>Pagamenti</h2>
                                                {
                                                    payment.totalPayments &&
                                                    <div className="mybadge">
                                                        {payment.totalPayments}
                                                    </div>
                                                }
                                            </div>

                                            <div className='col-6 text-end'></div>

                                            <div className='col-12 text-center'>
                                                {
                                                    controlRole(role.payment, "api/Payment", "POST") &&
                                                    <div className="col text-right">
                                                        <button className="btn btn-success removeMargin" onClick={this.hasClickModalCreatePayment}>
                                                            <i className="fas fa-plus" style={{ 'marginRight': '5px' }} /> Crea Pagamento
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <IconLicence legend forPayment />

                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                                <DataTable
                                                    columns={columns}
                                                    data={payment.listPayments}
                                                    customStyles={customStyles}
                                                    noHeader
                                                    striped
                                                    highlightOnHover
                                                    pointerOnHover
                                                    clearSelectedRows
                                                    expandableRows={payment.isLoading ? false : true}
                                                    expandOnRowClicked
                                                    expandableRowsComponent={ExpanableComponent}
                                                    expandableRowsComponentProps={{
                                                        "changeDevice": this.hasClickModalChange,
                                                        "getPaymentList": getPaymentList,
                                                        "setIdVpnPassForDetail": setIdVpnPassForDetail,
                                                        "handleNavigation": this.props.handleNavigation,
                                                        "errorNotify": errorNotify,
                                                        "successNotify": successNotify,
                                                        "infoNotify": infoNotify,
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {
                                this.state.hasClickModalChange &&
                                <ModalChangePayment
                                    titleModal="Associa Pagamento a un nuovo Device"
                                    updateDevicePayment={this.updateDevicePayment}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modalChangePayment}
                                    deviceId={this.state.deviceId}
                                    paymentId={this.state.paymentId}
                                    providerTransactionId={this.state.providerTransactionId}
                                    errorNotify={errorNotify}
                                />
                            }

                            {
                                this.state.hasClickModalCreatePayment &&
                                <ModalCreatePayment
                                    titleModal="Crea un nuovo pagamento"
                                    toggle={this.toggleModal}
                                    getPaymentList={getPaymentList}
                                    stateModal={this.state.modalCreatePayment}

                                    successNotify={successNotify}
                                    errorNotify={errorNotify}
                                    preferences={preferences}
                                />
                            }

                            {
                                this.state.hasClickModalRefundPayment &&
                                <ModalRefundPayment
                                    titleModal="Rimborsa un pagamento"
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modalRefundPayment}
                                    dataPayment={this.state.paymentTemp}

                                    refundPayment={refundPayment}
                                    successNotify={successNotify}
                                    errorNotify={errorNotify}
                                    preferences={preferences}
                                    payment={payment}
                                />
                            }

                            {
                                this.state.hasClickModalDeactiveSub &&
                                <Modal
                                    click={() => { this.props.deactiveSub(this.state.paymentId); this.toggleModal(); }}
                                    titleModal="Disattiva Sottoscrizione"
                                    contentModal={'Sei sicuro di voler disattivare la sottoscrizione ' + this.state.paymentId + ' ?'}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modalDeactiveSub}
                                />
                            }

                            {
                                this.state.hasClickModalDeletePayment &&
                                <Modal
                                    click={() => { this.props.deletePayment(this.state.paymentId); this.toggleModal(); }}
                                    titleModal="Cancella pagamento"
                                    contentModal={'Sei sicuro di voler cancellare il pagamento ' + this.state.paymentId + ' ?'}
                                    toggle={this.toggleModal}
                                    stateModal={this.state.modalDeletePayment}
                                />
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }
}

export default withNavigate(Payments);