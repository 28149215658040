import React, { Component, Fragment } from 'react';
import './style.scss';
import Modal from '../../common/modal';
import { controlRole } from '../../common/actions/controlRole';
import { isUndefined } from '../../utility';

export default class actionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            titleModal: '',
            contentModal: '',
            action: ''
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    /**
     * Invia all'azione del click, il tipo di azione e i dati del player
     */
    actionClick = (action, player) => {
        this.props.actionClick(action, player);
    }

    setInfoModal = (titleModal, contentModal, action) => {
        this.setState({
            titleModal: titleModal,
            contentModal: contentModal,
            action: action
        });
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    render() {
        const { data, role, player, playlist } = this.props;

        console.log("data: ", data);

        return (
            <Fragment>
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-ellipsis-v"></i>
                    </button>
                    {
                        !isUndefined(playlist) ?
                            <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                                {
                                    controlRole(role.player, "api/Player/UpdatePlaylist", "PUT") &&
                                    <li>
                                        <div className="dropdown-item" onClick={() => { this.props.actionClick('editPlaylist', playlist.id, playlist.deviceId, playlist.title, playlist.source); }}>
                                            <i className="far fa-edit orange"></i>
                                            Modifica Playlist
                                        </div>
                                    </li>
                                }

                                {
                                    controlRole(role.player, "api/Player/UpdatePlaylist", "PUT") &&
                                    <li>
                                        <div className="dropdown-item" onClick={() => { this.props.actionClick('removePlaylist', playlist.id, playlist.deviceId); }}>
                                            <i className="far fa-thumbs-down red"></i>
                                            Rimuovi Playlist
                                        </div>
                                    </li>
                                }
                            </ul>
                            :
                            <ul className={data.length < 4 ? "dropdown-menu fixed" : "dropdown-menu"}>
                                {
                                    (
                                        player.licence !== null &&
                                        player.licence.expireAt === null &&
                                        controlRole(role.player, "api/Player", "PUT")
                                    ) &&
                                    <li>
                                        <div className="dropdown-item" onClick={() => { this.setInfoModal('Disattiva Licenza', 'Verrà assegnata una licenza non valida a questo player. Continuare ?', 'deactiveLicence'); }}>
                                            <i className="far fa-thumbs-down red"></i>
                                            Disattiva Licenza
                                        </div>
                                    </li>
                                }

                                {
                                    controlRole(role.player, "api/Player/Reboot", "POST") &&
                                    <li>
                                        <div className="dropdown-item" onClick={() => { this.setInfoModal('Riavvia Player', 'Sei sicuro di voler riavviare questo player?', 'refreshPlayer'); }}>
                                            <i className="fas fa-sync-alt green"></i>
                                            Riavvia Player
                                        </div>
                                    </li>
                                }

                                {
                                    controlRole(role.player, "api/Player/SendMessage", "POST") &&
                                    <li>
                                        <div className="dropdown-item" onClick={() => { this.actionClick('sendMessagePlayer', player); }}>
                                            <i className="fas fa-comment-dots blue"></i>
                                            Messaggio al Player
                                        </div>
                                    </li>
                                }

                                {
                                    controlRole(role.player, "api/Player", "PUT") &&
                                    <li>
                                        <div className="dropdown-item" onClick={() => { this.actionClick('editPlayer', player); }}>
                                            <i className="far fa-edit orange"></i>
                                            Modifica Player
                                        </div>
                                    </li>
                                }

                                {
                                    controlRole(role.player, "api/Player", "DELETE") &&
                                    <li>
                                        <div className="dropdown-item" onClick={() => { this.setInfoModal('Elimina Player', 'Sei sicuro di voler eliminare questo player?', 'deletePlayer'); }}>
                                            <i className="far fa-edit red"></i>
                                            Rimuovi Player
                                        </div>
                                    </li>
                                }
                            </ul>
                    }
                </div>

                <Modal
                    click={() => { this.actionClick(this.state.action, player); this.toggleModal(); }}
                    titleModal={this.state.titleModal}
                    contentModal={this.state.contentModal}
                    toggle={this.toggleModal}
                    stateModal={this.state.modal}
                />
            </Fragment>
        )
    }
}